
import { Component, ViewChild, TemplateRef } from '@angular/core';
import { disputes } from '../_models/classes/consumer-discloser-dispute.model';
import { DialogdisputeComponent } from '../dailog/dialog-dispute/dialog-dispute.component';
import { DialogdisclosureComponent } from '../dailog/dialog-disclosure/dialog-disclosure.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogDispute } from '../dailog/dialog-dispute/dialog-dispute.component';
import { DialogDisputeResult } from '../dailog/dialog-dispute/dialog-dispute.component';
import { DialogInput } from '../dailog/dialog-disclosure/dialog-disclosure.component';
import { DialogInfo, DialogReportedInfoComponent, DialogDisputeDetailsInfo, DialogDisputeDetailsInfoItem } from '../dailog/dialog-ReportedInfo/dialog-ReportedInfo.component';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { HttpClient } from '@angular/common/http';
import { RufDynamicMenubarItem } from '@ruf/shell';
import { DisputeTabsService } from '../_services/dispute-tabs/dispute-tabs.service';
import { RufDynamicSegmentItem } from '@ruf/shell';
import { userinformation } from '../_models/classes/user-information.model';
import { Guid } from "guid-typescript";
import { TranslateService } from '@ngx-translate/core';
import { DialogwithmessagebtnComponent } from '../dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { DialogMessage} from '../dailog/dialog-with-message/dialog-with-message.component';
import { DialogwithmessageComponent } from '../dailog/dialog-with-message/dialog-with-message.component';
import { GlobalVariables } from '../common/enums/global_variables';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { RufDropdownType } from '@ruf/shell';
import { CommonSnackbarComponent } from '../common/common-snackbar/common-snackbar.component';

@Component({
  selector: 'chx-consumer-disclosure-dispute',
  templateUrl: './consumer-disclosure-dispute.component.html',
  styleUrls: ['./consumer-disclosure-dispute.component.scss'],
})


export class ConsumerDisclosureDisputeComponent {

  selectedPath: string = "PersonalInformation";
  disputeRoot:disputes.DisputeRoot;
  dialogWidth:string = "477px";
  dialogheight: string = "1178px";
  cddData: disputes.disputeData = new disputes.disputeData();
  piButtonText:string = "Dispute";
  piButtonTextHT:string = "Block";
  piButtonTextAfter:string = "Selected";
  badgeColor = 'emphasis';
  disputeRaisedCount:number = 0;
  public disputeSubmitData: disputes.DisputeDiscloserSubmitData[]=[];
  segmentItems: RufDynamicSegmentItem[] = [];
  selectedSegmentItem:string = "Name";
  title: any = '';
  items : any = '';
  closuredispute=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.INFO1');
  noitems=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.NOITEMS');
  name=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.NAME');
  action=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.ACTION');
  noitems1=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.NOITEMS');
  address=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.ADDRESS');
  noitems2=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.NOITEMS');
  email=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.EMAIL');
  phone=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.PHONE');
  phntype=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.PHONE TYPE');
  dob=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.DOB');
  reportedinfo=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.REPORTEDINFO');
  source=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.SOURCE');
  dateclosure=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.DATECLOSURE');
  closurestatus=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.CLOSURESTATUS');
  inquiries=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.INQUIRIES');
  inquirydate=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.INQUIRYDATE');
  check=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.CHECK');
  inquirer=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.INQUIRER');
  date=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.DATE');
  inquiriesview=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.INQUIRIESVIEW');
  retail=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.RETAIL');
  payee=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.PAYEE');
  amount=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.AMOUNT');
  action8=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.ACTION');
  history=this._translate.instant('CONSUMERDISCLOSUREDISPUTE.HISTORY');
  showLoadingIndicator:boolean = false;
  isHumanTrafficking = false;
  panel = { type: RufDropdownType.PopoverMenu };
  panelClass = ['fis-style', 'ruf-info', 'ruf-inkbar-bottom', 'chex-common-dispute-snackbar'];
  position: string = 'top-center';
  constructor(public dialog: MatDialog, private router: Router, 
    private route: ActivatedRoute,
    private piConfirmService: PersonalInformationConfirmationService,
    private http: HttpClient,
    private disputeTabsService:DisputeTabsService,
    private snackBar: MatSnackBar,
    private _translate: TranslateService,){
      this.isHumanTrafficking = Object.keys(this.route.snapshot.data).length !== 0 ? true : false;
      this.title= !this.isHumanTrafficking ? "Consumer Disclosure - Dispute": "Consumer Disclosure - Block";
      this.items= !this.isHumanTrafficking ? "Items to Dispute": "Items to Block";
      disputeTabsService.resetDisputeItemAfterDeleteEvent$.subscribe(item => this.resetDisputeItemAfterDelete(item));
      disputeTabsService.resetAllAfterDeleteEvent$.subscribe(item => this.resetAllAfterDelete(item));
      this.showLoadingIndicator = true;
      this.getDisputeRespData().subscribe((res) => {
      this.disputeRoot = res as disputes.DisputeRoot;
      if((res.DisputeEventsResponse && res.DisputeEventsResponse.Code === "4014") || (res.Code === "4014")){
          const dialogMessage = new DialogMessage(
            this._translate.instant(
              'DISPUTETABS.DISPUTEEVENTAPITEXTNORECORDS'
            ),
            this._translate.instant(
              'BUTTONS.GOTODASHBOARD'
            ),
            false,
            this._translate.instant(
              'DISPUTETABS.TRANSITIONPOPUPTITLE'
            )
          );
          const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
            panelClass: ["fis-style", "custom-dialog-without-close"],
            data: dialogMessage,
            disableClose: true
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if ((result as string) == 'OK') {
              this.router.navigate(['dashboard']);
            }
          });
      }
      else if(res.Code === "0"){
        this.cddData = this.initDisputeData();
        this.cddData.reportedInfo = this.initRptiData();
        this.cddData.inquiriesVBO = this.initIVBOData();
        this.cddData.inquiriesVBY = this.initIVBYData();
        this.cddData.checkCashingInq = this.initCCIData();
        this.cddData.retailInfo = this.initRIData();
        this.cddData.historyofCO = this.initHCOData();
      }
      else {
        let popupMsg = this.isHumanTrafficking ?  this._translate.instant(
          'DISPUTETABS.DISPUTEEVENTAPITEXTFORHT') :  this._translate.instant(
          'DISPUTETABS.DISPUTEEVENTAPITEXT');
          const dialogMessage = new DialogMessage(
            popupMsg,
            this._translate.instant(
              'BUTTONS.GOTODASHBOARD'
            ),
            false,
            this._translate.instant(
              'DISPUTETABS.DISPUTEEVENTAPITITLE'
            )
          );
          const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
            panelClass: ["fis-style", "custom-dialog-without-close"],
            data: dialogMessage,
            disableClose: true
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if ((result as string) == 'OK') {
              this.router.navigate(['dashboard']);
            }
          });
      }
      
    }, 
    error => { this.showLoadingIndicator = false;},
    () => {this.showLoadingIndicator = false; });
    
  }

    ngOnInit(): void {

    this.segmentItems = [
      {
        label: 'Name',
        path: 'Name'
      },
      {
        label: 'Address',
        path: 'Address'
      },
      {
        label: 'Email Address',
        path: 'EmailAddress'
      },
      {
        label: 'Phone',
        path: 'Phone'
      },
      {
        label: 'Date of Birth',
        path: 'DateofBirth'
      }
    ];

    // Check user profile confirm
    this.piConfirmService.checkPersonalInformationConfirmation();
    // this.isLoader = true;
    //   this.getDisputeRespData().subscribe((res) => {
    //   this.disputeRoot = res as disputes.DisputeRoot;
    //   alert(this.disputeRoot.Cid);
    //   this.cddData = this.initDisputeData();
    //   this.cddData.reportedInfo = this.initRptiData();
    //   this.cddData.inquiriesVBO = this.initIVBOData();
    //   this.cddData.inquiriesVBY = this.initIVBYData();
    //   this.cddData.checkCashingInq = this.initCCIData();
    //   this.cddData.retailInfo = this.initRIData();
    //   this.cddData.historyofCO = this.initHCOData();
    // }, 
    // error => {
    //   this.isLoader = false;
    // },
    // () => {
    //   this.isLoader = false;
    // });
  }

  getCaptionValueDetails(code: string,  element:any){
    var tempData:DialogDisputeDetailsInfo = new DialogDisputeDetailsInfo();
    tempData.SectionButtonText = this.isHumanTrafficking ? "Block": "Dispute";
    tempData.isHumanTrafficking = this.isHumanTrafficking;
    const datepipe: DatePipe = new DatePipe('en-US')
    if(code === 'rpti'){
      tempData.header = "Reported Information";
      tempData.category = "Reported information";
      var objrptiItem = element as disputes.reportedInformationItem;
      tempData.isSectionDisputed = objrptiItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Source of Information :";
      item1.value = objrptiItem.sourceOfInformation;
      tempData.data = objrptiItem.sourceOfInformation;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Reported For :";
      item1.value = objrptiItem.reportedFor;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Reported Name :";
      item1.value = objrptiItem.reportedName;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Reported Address :";
      item1.value = objrptiItem.reportedAddress;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Reported SSN/ID :";
      item1.value = objrptiItem.reportedID;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Driver’s License :";
      item1.value = objrptiItem.driverLicense;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Date of Closure :";
      item1.value = objrptiItem.dateofClosure;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "RTN/ACCT# :";
      item1.value = objrptiItem.rtn;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Original Charge Off Amount : ";
      item1.value = objrptiItem.originalChargeOffAmount.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Closure Status :";
      item1.value = objrptiItem.closureStatus;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Closure Status Date :";
      item1.value = objrptiItem.closureStatusDate;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

    }
    else if(code === 'ivbo'){
      tempData.header = "Inquiries Viewed by Others";
      tempData.category = "Inquiries viewed by Others";
      var objivboItem = element as disputes.inquiriesViewedByOthersItem;
      tempData.data = objivboItem.inquirerName;
      tempData.isSectionDisputed = objivboItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquirer :";
      item1.value = objivboItem.inquirer;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Phone Number :";
      item1.value = objivboItem.iPhoneNumber;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "On Behalf Of :";
      item1.value = objivboItem.onBehalfOf;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Phone Number :";
      item1.value = objivboItem.oPhoneNumber;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // **** commented as part of this US1758644 ****
      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Name :";
      // item1.value = objivboItem.name;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquiry Date :";
      item1.value = datepipe.transform(objivboItem.inquiryDate, 'MM/dd/yyyy') as string;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Address :";
      // item1.value = objivboItem.address;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "SSN/ID Provided :";
      // item1.value = objivboItem.ssnID;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Driver’s License :";
      // item1.value = objivboItem.driverLicense;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "State :";
      // item1.value = objivboItem.state;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Phone Numbers :";
      // item1.value = objivboItem.phoneNumbers;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Email :";
      // item1.value = objivboItem.email;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

    }
    else if(code === 'cci'){
      tempData.header = "Check Cashing Inquiries";
      tempData.category = "Check Cashing Inquiries";
      var objcciItem = element as disputes.checkCashingInquiriesItem;
      tempData.data = objcciItem.inquirer;
      tempData.isSectionDisputed = objcciItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquirer :";
      item1.value = objcciItem.inquirer;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquiry Date :";
      item1.value = datepipe.transform(objcciItem.inquiryDate, 'MM/dd/yyyy') as string;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquiry Dollar Amount :";
      item1.value = objcciItem.inquiryDollarAmount.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "SSN/ID Provided :";
      // item1.value = objcciItem.ssnID;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Driver's License :";
      // item1.value = objcciItem.driverLicense;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "State :";
      // item1.value = objcciItem.state;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);
    }
    else if(code === 'ivby'){
      tempData.header = "Inquiries Viewed Only by You";
      tempData.category = "Inquiries Viewed Only by You";
      var objivbyItem = element as disputes.inquiriesViewedByOthersItem;
      tempData.data = objivbyItem.inquirerName;
      tempData.isSectionDisputed = objivbyItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquirer :";
      item1.value = objivbyItem.inquirer;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Phone Number :";
      item1.value = objivbyItem.iPhoneNumber;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "On Behalf Of :";
      item1.value = objivbyItem.onBehalfOf;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Phone Number :";
      item1.value = objivbyItem.oPhoneNumber;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Name :";
      // item1.value = objivbyItem.name;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Inquiry Date :";
      item1.value = datepipe.transform(objivbyItem.inquiryDate, 'MM/dd/yyyy') as string;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Address :";
      // item1.value = objivbyItem.address;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "SSN/ID Provided :";
      // item1.value = objivbyItem.ssnID;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Driver’s License :";
      // item1.value = objivbyItem.driverLicense;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "State :";
      // item1.value = objivbyItem.state;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Phone Numbers :";
      // item1.value = objivbyItem.phoneNumbers;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);

      // var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      // item1.caption = "Email :";
      // item1.value = objivbyItem.email;
      // tempData.dialogDisputeDetailsInfoItemList.push(item1);
    }
    else if(code === 'rti'){
      tempData.header = "Retail Information";
      tempData.category = "Retail Information";
      var objrtiItem = element as disputes.retailInformationItem;
      tempData.isSectionDisputed = objrtiItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Payee :";
      item1.value = objrtiItem.payee;
      tempData.data = objrtiItem.payee;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Amount Of Check :";
      item1.value = objrtiItem.amountOfCheck.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Date of Check :";
      item1.value = objrtiItem.dateofCheck;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Check Number :";
      item1.value = objrtiItem.checkNumber.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Date Check Paid :";
      item1.value = objrtiItem.dateCheckPaid;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Driver's License :";
      item1.value = objrtiItem.amountOfCheck.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "State :";
      item1.value = objrtiItem.amountOfCheck.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "RTN/ACCT# :";
      item1.value = objrtiItem.rtn;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

    }

    else if(code === 'hco'){
      tempData.header = "History of Checks Ordered";
      tempData.category = "History of Checks ordered";
      var objhcoItem = element as disputes.historyofChecksOrderedItem;
      tempData.isSectionDisputed = objhcoItem.isSectionDisputed;

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Source of Information :";
      item1.value = objhcoItem.sourceofInformation;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Phone Number :";
      item1.value = objhcoItem.phoneNumber;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Name :";
      item1.value = objhcoItem.name;
      tempData.data = objhcoItem.name;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "RTN/ACCT# :";
      item1.value = objhcoItem.rtn;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Check Start Number :";
      item1.value = objhcoItem.checkStartNumber.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "SSN/ID:";
      item1.value = objhcoItem.ssnID;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Quantity Ordered :";
      item1.value = objhcoItem.quantityOrdered.toString();
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Driver's License :";
      item1.value = objhcoItem.driverLicense;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "Date Order Shipped :";
      item1.value = datepipe.transform(objhcoItem.dateOrderShipped, 'MM/dd/yyyy') as string;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);

      var item1:DialogDisputeDetailsInfoItem = new DialogDisputeDetailsInfoItem();
      item1.caption = "State:";
      item1.value = objhcoItem.state;
      tempData.dialogDisputeDetailsInfoItemList.push(item1);
    }
    return tempData;
  }

  rowClickHandler(code: string, element:any){
    // if(!this.isHumanTrafficking) {
      console.log("row click-->", element);
    const dialogData = this.getCaptionValueDetails(code, element);
          const dialogRef = this.dialog.open(DialogReportedInfoComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            
            height:this.dialogheight,
            data:dialogData,
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            // alert(disptResult);
            // alert(disptResult.result);
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, element.DisputeDataHolder, element.uniqueID);
              element.isSectionDisputed = true;
              element.SectionButtonText = this.piButtonTextAfter;
              if(this.isHumanTrafficking){
                this.openSnackBar();
              }
            }
          });
  // }
}
  NameHandler(){
    // const dialogData = new DialogInfo("Inquiries Viewed By Others");
    //       const dialogRef = this.dialog.open(DialogReportedInfoComponent,{position
    //           : {top: '0%', right: '0%'},
    //         width:this.dialogWidth,
    //         height:this.dialogheight,
    //         data:dialogData,
           
    //       });
    //       dialogRef.afterClosed().subscribe(result => { 
           
    //       });

  }
  
  initDisputeData(){
    var tempCddData:disputes.disputeData;
    tempCddData = new disputes.disputeData();

    tempCddData.consumerid = this.disputeRoot.Cid;

    tempCddData.name = this.disputeRoot.NmeVartn.VarntNme[0];

    tempCddData.personalInfo = new disputes.personalInformation();

    tempCddData.personalInfo.DisputeAddress = [];
    this.disputeRoot.AddVartnDtl.AddVartn.forEach(element => {
      let tempObj:disputes.DisputeAddress = new disputes.DisputeAddress();
      tempObj.address = element.VarntAddr.VarntAddrLn1 + " " +element.VarntAddr.VarntAddrLn2 + "" + element.VarntAddr.CtyStZip;
      tempObj.DisputeDataHolder = new disputes.DisputeDataHolder();
      tempObj.DisputeDataHolder.VarntAddr = element.VarntAddr;
      tempObj.isSectionDisputed = false;
      tempObj.isSectionDisputable = true;
      tempObj.SectionButtonText = this.piButtonText;
      tempCddData.personalInfo.DisputeAddress.push(tempObj);
    });
    
    tempCddData.personalInfo.DisputeDataHolder = new disputes.DisputeDataHolder();
    tempCddData.personalInfo.DisputeDataHolder.DateOfBirth = this.disputeRoot.DateOfBirth
    tempCddData.personalInfo.dateOfBirth = new Date(this.disputeRoot.DateOfBirth);
    tempCddData.personalInfo.dateOfBirthIsDisputable = true;
    tempCddData.personalInfo.dateOfBirthButtonText = this.piButtonText;


    tempCddData.personalInfo.DisputeEmailAddress = [];
    this.disputeRoot.EmailAddrVartnDtl.EmailVartn.forEach(element => {
      let tempObj:disputes.DisputeEmailAddress = new disputes.DisputeEmailAddress();
      tempObj.emailAddress = element.EmailAddr;
      tempObj.DisputeDataHolder = new disputes.DisputeDataHolder();
      tempObj.DisputeDataHolder.EmailVartn = element;
      tempObj.isSectionDisputed = false;
      tempObj.isSectionDisputable = true;
      //tempObj.isSectionDisputable = this.getEmailDisputable(element.EmailAddr);
      tempObj.SectionButtonText = this.piButtonText;
      tempCddData.personalInfo.DisputeEmailAddress.push(tempObj);
    });
    
    tempCddData.personalInfo.DisputeName = [];
    this.disputeRoot.NmeVartn.VarntNme.forEach(element => {
      let tempObj:disputes.DisputeName = new disputes.DisputeName();
      tempObj.name = element;
      tempObj.DisputeDataHolder = new disputes.DisputeDataHolder();
      tempObj.DisputeDataHolder.VarntNme = element;
      tempObj.isSectionDisputed = false;
      tempObj.isSectionDisputable = true;
      tempObj.SectionButtonText = this.piButtonText;
      tempCddData.personalInfo.DisputeName.push(tempObj);
    });
    
    tempCddData.personalInfo.DisputePhone = [];
    this.disputeRoot.PhnVartnDtl.PhnVartn.forEach(element => {
      let tempObj:disputes.DisputePhone = new disputes.DisputePhone();
      tempObj.phoneNumber = element.PhnNmbr;
      tempObj.phntype = element.PhnType;
      tempObj.DisputeDataHolder = new disputes.DisputeDataHolder();
      tempObj.DisputeDataHolder.PhnVartn = element;
      tempObj.isSectionDisputed = false;
      tempObj.isSectionDisputable = true;
      //tempObj.isSectionDisputable = this.getPhoneDisputable(this.ConvertPhoneFormate(element.PhnNmbr));
      tempObj.SectionButtonText = this.piButtonText;
      tempCddData.personalInfo.DisputePhone.push(tempObj);
    });

    return tempCddData;
  }

  getEmailDisputable(email:string){
    let result: boolean = true;
    const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
    if (strUserInfo) {
      const objUserInfo = JSON.parse(
        strUserInfo
      ) as userinformation.UserInformationRoot;
      //alert(objUserInfo.NotificationEmailAddr);
      //alert(email);
      if(objUserInfo.NotificationEmailAddr.toUpperCase().trim() === email.toUpperCase().trim()){
        result = false;
      }
    }
    return result;
    //return false;
  }
  getPhoneDisputable(phone:string){
    let result: boolean = true;
    const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
    if (strUserInfo) {
      const objUserInfo = JSON.parse(
        strUserInfo
      ) as userinformation.UserInformationRoot;
      // alert(objUserInfo.CellPhoneNbr);
      // alert(objUserInfo.CellPhoneNbr);
      // alert(phone);
      if(objUserInfo.CellPhoneNbr.toUpperCase().trim() === phone.toUpperCase().trim()){
        result = false;
      }
      if(objUserInfo.CnsmrPrsnlDetls.PhoneNbr.toUpperCase().trim() === phone.toUpperCase().trim()){
        result = false;
      }
    }
    return result;
    //return false;
  }
  onSubmit(){
    const inputData:DialogInput = new DialogInput(this.disputeSubmitData);
    //alert(this.disputeSubmitData[0].reason);
    const dialogRef = this.dialog.open(DialogdisclosureComponent,{position
      : {top: '0%', right: '0%'},
    width:this.dialogWidth,
    height:this.dialogheight,
    data: inputData,
    disableClose: true}
    );
    dialogRef.afterClosed().subscribe(result => { 
    });
  }
  getCodeName(code:string){
    if(code === "pi-name"){
      return "Name";
    }
    else if(code === "pi-address"){
      return "Address";
    }
    else if(code === "pi-phone"){
      return "Phone";
    }
    else if(code === "pi-email"){
      return "Email";
    }
    else if(code === "pi-dob"){
      return "Date of birth";
    }

    return "";
  }
  disputeClick(code:string, row?: any | null, isDisabled: boolean = false){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if(isDisabled){
      return;
    }
    if(code === "pi-dob" && this.cddData.personalInfo.dateOfBirthIsDisputed){
      return;
    }
    else if(row && row.isSectionDisputed === true){
      return;
    }
    
      const dialogData = new DialogDispute(this.getCodeName(code), "Personal Information");
      const dialogRef = this.dialog.open(DialogdisputeComponent,{position
          : {top: '0%', right: '0%'},
        width:this.dialogWidth,
        height:this.dialogheight,
        panelClass: ["fis-style", "custom-dialog"],
        data:dialogData,
        disableClose: true
      }
      );
    dialogRef.afterClosed().subscribe(result => { 
      var disptResult:DialogDisputeResult = result as DialogDisputeResult;
      if(disptResult.result === "OK"){
        //this.disputeRaisedCount = this.disputeRaisedCount + 1;
        if(code === "pi-dob"){
          this.addDisputeDataForSubmit(disptResult, this.cddData.personalInfo.DisputeDataHolder, this.cddData.personalInfo.uniqueID);
          this.cddData.personalInfo.dateOfBirthIsDisputed = true;
          this.cddData.personalInfo.dateOfBirthButtonText = this.piButtonTextAfter;
        }
        else {
          this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
          row.isSectionDisputed = true;
          row.SectionButtonText = this.piButtonTextAfter;
        }
    }
    });
    
  }

  showDisputeRaised(){
    const inputData:DialogInput = new DialogInput(this.disputeSubmitData);
    //alert(this.disputeSubmitData[0].reason);
    const dialogRef = this.dialog.open(DialogdisclosureComponent,{position
      : {top: '0%', right: '0%'},
    width:this.dialogWidth,
    height:this.dialogheight,
    data: inputData,
    disableClose: true}
    );
    dialogRef.afterClosed().subscribe(result => { 
    });
  }

  initRptiData(){
    var tempRptiData:disputes.reportedInformation;
    tempRptiData = new disputes.reportedInformation();
    for (var item of this.disputeRoot.DisputeEventsList.ClsrEventDtl.ClsrEvent) {
      if(item.ClsrPrvdrNme.trim() !== ""){
        var tempRptiItemData1 = new disputes.reportedInformationItem();
        tempRptiItemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        tempRptiItemData1.DisputeDataHolder.ClsrEvent = item;
        tempRptiItemData1.sourceOfInformation = item.ClsrPrvdrNme + "; " + item.ClsrPrvdrAddr.ClsrPrvdrAddrLn1 + " " + item.ClsrPrvdrAddr.ClsrPrvdrCty
                                                + " " + item.ClsrPrvdrAddr.ClsrPrvdrSt + " " + item.ClsrPrvdrAddr.ClsrPrvdrZip;
                                                + " " + item.ClsrPrvdrAddr.ClsrPrvdrSt + " " + item.ClsrPrvdrAddr.ClsrPrvdrZip;
        //tempRptiItemData1.sourceOfInformation = this.disputeRoot.DisputeEventsList.;
        tempRptiItemData1.sourceOfInformationIsDisputable = false;
        tempRptiItemData1.sourceOfInformationIsDisputed = false;
        tempRptiItemData1.sourceOfInformationButtonText = this.piButtonText;

        tempRptiItemData1.phoneNumber = item.ClsrPrvdrPhn;
        tempRptiItemData1.phoneNumberIsDisputable = false;
        tempRptiItemData1.phoneNumberIsDisputed = false;
        tempRptiItemData1.phoneNumberButtonText = this.piButtonText;
        
        //tempRptiItemData1.reportedFor = "SUSPECTED FRAUD ACTIVITY";
        tempRptiItemData1.reportedFor = item.ClsrRsns.ClsrRsn1Desc.trim() + " " + item.ClsrRsns.ClsrRsn2Desc.trim() + " " + item.ClsrRsns.ClsrRsn3Desc.trim();
        tempRptiItemData1.reportedForIsDisputable = true;
        tempRptiItemData1.reportedForIsDisputed = false;
        tempRptiItemData1.reportedForButtonText = this.piButtonText;

        //tempRptiItemData1.reportedName = "JOHN CARTER CONSUMER";
        tempRptiItemData1.reportedName = item.ClsrCnsmrNme.ClsrCnsmrFrstNme + " " + item.ClsrCnsmrNme.ClsrCnsmrMidNme + " " + item.ClsrCnsmrNme.ClsrCnsmrLstNme;
        tempRptiItemData1.reportedNameIsDisputable = true;
        tempRptiItemData1.reportedNameIsDisputed = false;
        tempRptiItemData1.reportedNameButtonText = this.piButtonText;

        //tempRptiItemData1.reportedAddress = "123 MAIN ST ANYTOWN, MN 55125-0000";
        tempRptiItemData1.reportedAddress = item.ClsrPrvdrAddr.ClsrPrvdrAddrLn1;
        tempRptiItemData1.reportedAddressIsDisputable = true;
        tempRptiItemData1.reportedAddressIsDisputed = false;
        tempRptiItemData1.reportedAddressButtonText = this.piButtonText;

        //tempRptiItemData1.reportedID = "XXX-XX-1234";
        tempRptiItemData1.reportedID = this.getMasked(item.ClsrGovtNbr, 4);
        tempRptiItemData1.reportedIDIsDisputable = true;
        tempRptiItemData1.reportedIDIsDisputed = false;
        tempRptiItemData1.reportedIDButtonText = this.piButtonText;

        tempRptiItemData1.driverLicense = this.getMasked(item.ClsrDlNbr, 4);
        tempRptiItemData1.driverLicenseIsDisputable = true;
        tempRptiItemData1.driverLicenseIsDisputed = false;
        tempRptiItemData1.driverLicenseButtonText = this.piButtonText;

        tempRptiItemData1.state = item.ClsrDlStCd;
        tempRptiItemData1.stateIsDisputable = true;
        tempRptiItemData1.stateIsDisputed = false;
        tempRptiItemData1.stateButtonText = this.piButtonText;

        //tempRptiItemData1.dateofClosure = "01/01/2021";
        tempRptiItemData1.dateofClosure = item.ClsrRptdDt;
        tempRptiItemData1.dateofClosureIsDisputable = true;
        tempRptiItemData1.dateofClosureIsDisputed = false;
        tempRptiItemData1.dateofClosureButtonText = this.piButtonText;

        //tempRptiItemData1.rtn = " 012345678 / XXXXXXXXXXXX9876";
        tempRptiItemData1.rtn = item.ClsrBnkCd;
        tempRptiItemData1.rtnIsDisputable = true;
        tempRptiItemData1.rtnIsDisputed = false;
        tempRptiItemData1.rtnButtonText = this.piButtonText;

        tempRptiItemData1.accountNumber = this.getMasked(item.ClsrAcctNbr, 4);
        tempRptiItemData1.accountNumberIsDisputable = false;
        tempRptiItemData1.accountNumberIsDisputed = false;
        tempRptiItemData1.accountNumberButtonText = this.piButtonText;

        tempRptiItemData1.originalChargeOffAmount = Number(item.ClsrOrigChrgOffAmt);
        tempRptiItemData1.originalChargeOffAmountIsDisputable = true;
        tempRptiItemData1.originalChargeOffAmountIsDisputed = false;
        tempRptiItemData1.originalChargeOffAmountButtonText = this.piButtonText;

        //tempRptiItemData1.closureStatus = "UNPAID";
        tempRptiItemData1.closureStatus = item.ClsrStatDesc;
        tempRptiItemData1.closureStatusIsDisputable = true;
        tempRptiItemData1.closureStatusIsDisputed = false;
        tempRptiItemData1.closureStatusButtonText = this.piButtonText;

        //tempRptiItemData1.closureStatusDate = "01/01/2021";
        tempRptiItemData1.closureStatusDate = item.ClsrStlmtDt;
        tempRptiItemData1.closureStatusDateIsDisputable = true;
        tempRptiItemData1.closureStatusDateIsDisputed = false;
        tempRptiItemData1.closureStatusDateButtonText = this.piButtonText;

        tempRptiItemData1.isSectionDisputed = false;
        tempRptiItemData1.isSectionDisputable = true;
        tempRptiItemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;

        tempRptiData.reportedInfoItemList.push(tempRptiItemData1);

      }
      
      
    }
    // //------------------------------------------------

    return tempRptiData;
  }

  initIVBOData(){
    var tempData:disputes.inquiriesViewedByOthers;
    tempData = new disputes.inquiriesViewedByOthers();
    for (var item of this.disputeRoot.DisputeEventsList.InqryEventDtl.InqryEvent) {
      if(item.InqProdPostCtgyCd === "H"){
        var temptemData1 = new disputes.inquiriesViewedByOthersItem();
        //temptemData1.name = "JOHN C CONSUMER";
        temptemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        temptemData1.DisputeDataHolder.InqryEvent = item;
        // temptemData1.name = item.InqNme.InqFrstNme + " " + item.InqNme.InqMidNme + " " + item.InqNme.InqLstNme;
        temptemData1.inquirerName = item.InqCustNme;
        temptemData1.nameIsDisputable = false;
        temptemData1.nameIsDisputed = false;
        temptemData1.nameButtonText = this.piButtonText;
    
        temptemData1.inquiryDate = new Date(item.InqDt);
        temptemData1.inquiryDateIsDisputable = true;
        temptemData1.inquiryDateIsDisputed = false;
        temptemData1.inquiryDateButtonText = this.piButtonText;
    
        if(item.InqMstrCustDtl && item.InqMstrCustDtl.InqMastCustNme.trim() !== ""){
          //temptemData1.inquirer = "SECOND NATIONAL BANK; 111 SOUTH ST, ANYTOWN, MN, 55125";
          temptemData1.inquirer = item.InqMstrCustDtl.InqMastCustNme + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn1
                                  + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustCty + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustSt
                                  + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustZip;
          temptemData1.inquirerIsDisputable = true;
          temptemData1.inquirerIsDisputed = false;
          temptemData1.inquirerButtonText = this.piButtonText;
      
          temptemData1.iPhoneNumber = item.InqMstrCustDtl.InqMstrCustPhn;
          temptemData1.iPhoneNumberIsDisputable = true;
          temptemData1.iPhoneNumberIsDisputed = false;
          temptemData1.iPhoneNumberButtonText = this.piButtonText;

          temptemData1.onBehalfOf = item.InqCustNme + " " + item.InqCustAddr.InqCustAddrLn1 + " " + item.InqCustAddr.InqCustCty
          + " " + item.InqCustAddr.InqCustSt + " " + item.InqCustAddr.InqCustZip;
          temptemData1.onBehalfOfIsDisputable = false;
          temptemData1.onBehalfOfIsDisputed = false;
          temptemData1.onBehalfOfButtonText = this.piButtonText;

          temptemData1.oPhoneNumber = item.InqCustPhn;
          temptemData1.oPhoneNumberIsDisputable = false;
          temptemData1.oPhoneNumberIsDisputed = false;
          temptemData1.oPhoneNumberButtonText = this.piButtonText;
        }
        else{
          temptemData1.inquirer = item.InqCustNme + " " + item.InqCustAddr.InqCustAddrLn1 + " " + item.InqCustAddr.InqCustCty
                                  + " " + item.InqCustAddr.InqCustSt + " " + item.InqCustAddr.InqCustZip;
          temptemData1.inquirerIsDisputable = true;
          temptemData1.inquirerIsDisputed = false;
          temptemData1.inquirerButtonText = this.piButtonText;
      
          temptemData1.iPhoneNumber = item.InqCustPhn;
          temptemData1.iPhoneNumberIsDisputable = true;
          temptemData1.iPhoneNumberIsDisputed = false;
          temptemData1.iPhoneNumberButtonText = this.piButtonText;

          temptemData1.onBehalfOf = "";
          temptemData1.onBehalfOfIsDisputable = false;
          temptemData1.onBehalfOfIsDisputed = false;
          temptemData1.onBehalfOfButtonText = this.piButtonText;

          temptemData1.oPhoneNumber = "";
          temptemData1.oPhoneNumberIsDisputable = false;
          temptemData1.oPhoneNumberIsDisputed = false;
          temptemData1.oPhoneNumberButtonText = this.piButtonText;
        }
        
        
        temptemData1.address = item.InqAddr.InqAddrLn1 + " " + item.InqAddr.InqCty
                                + " " + item.InqAddr.InqSt + " " + item.InqAddr.InqZip;
        temptemData1.addressIsDisputable = false;
        temptemData1.addressIsDisputed = false;
        temptemData1.addressButtonText = this.piButtonText;
        
        temptemData1.ssnID = this.getMasked(item.InqGovtNbr, 4);
        temptemData1.ssnIDIsDisputable = false;
        temptemData1.ssnIDIsDisputed = false;
        temptemData1.ssnIDButtonText = this.piButtonText;
        
        temptemData1.driverLicense = this.getMasked(item.InqDlNbr, 4);
        temptemData1.driverLicenseIsDisputable = false;
        temptemData1.driverLicenseIsDisputed = false;
        temptemData1.driverLicenseButtonText = this.piButtonText;
    
        temptemData1.state = item.InqDlStCd;
        temptemData1.stateIsDisputable = false;
        temptemData1.stateIsDisputed = false;
        temptemData1.stateButtonText = this.piButtonText;
    
        // for(var item2 of this.disputeRoot.PhnVartnDtl.PhnVartn){
        //   temptemData1.phoneNumbers = temptemData1.phoneNumbers + (temptemData1.phoneNumbers === ""? "" : ",") +item2.PhnNmbr;
        // }
        temptemData1.phoneNumbers = item.InqEvntHmePhn.trim();
        if(item.InqEvntWrkPhn.trim() !== ""){
          temptemData1.phoneNumbers = temptemData1.phoneNumbers + (temptemData1.phoneNumbers === ""? "" : ",") + item.InqEvntWrkPhn.trim();
        }

        temptemData1.phoneNumbersIsDisputable = false;
        temptemData1.phoneNumbersIsDisputed = false;
        temptemData1.phoneNumbersButtonText = this.piButtonText;
    
        temptemData1.email = item.InqEvntEmailAddr;
        temptemData1.emailIsDisputable = false;
        temptemData1.emailIsDisputed = false;
        temptemData1.emailButtonText = this.piButtonText;
    
    
        temptemData1.isSectionDisputed = false;
        temptemData1.isSectionDisputable = true;
        temptemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT : this.piButtonText;
    
        tempData.inquiriesViewedByOthersItemList.push(temptemData1);

      }
      
    }

    //-----------------------------------------------

    return tempData;
  }

  initIVBYData(){
    var tempData:disputes.inquiriesViewedByOthers;
    tempData = new disputes.inquiriesViewedByOthers();
    
    for (var item of this.disputeRoot.DisputeEventsList.InqryEventDtl.InqryEvent) {
      if(item.InqProdPostCtgyCd === "S"){
        var temptemData1 = new disputes.inquiriesViewedByOthersItem();
        temptemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        temptemData1.DisputeDataHolder.InqryEvent = item;
        //temptemData1.name = "JOHN C CONSUMER";
        temptemData1.name = item.InqNme.InqFrstNme + " " + item.InqNme.InqMidNme + " " + item.InqNme.InqLstNme;
        temptemData1.inquirerName = item.InqCustNme;
        temptemData1.nameIsDisputable = false;
        temptemData1.nameIsDisputed = false;
        temptemData1.nameButtonText = this.piButtonText;
    
        temptemData1.inquiryDate = new Date(item.InqDt);
        temptemData1.inquiryDateIsDisputable = true;
        temptemData1.inquiryDateIsDisputed = false;
        temptemData1.inquiryDateButtonText = this.piButtonText;
    
        if(item.InqMstrCustDtl && item.InqMstrCustDtl.InqMastCustNme.trim() !== ""){
          //temptemData1.inquirer = "SECOND NATIONAL BANK; 111 SOUTH ST, ANYTOWN, MN, 55125";
          temptemData1.inquirer = item.InqMstrCustDtl.InqMastCustNme + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn1
                                  + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustCty + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustSt
                                  + " " + item.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustZip;
          temptemData1.inquirerIsDisputable = true;
          temptemData1.inquirerIsDisputed = false;
          temptemData1.inquirerButtonText = this.piButtonText;
      
          temptemData1.iPhoneNumber = item.InqMstrCustDtl.InqMstrCustPhn;
          temptemData1.iPhoneNumberIsDisputable = true;
          temptemData1.iPhoneNumberIsDisputed = false;
          temptemData1.iPhoneNumberButtonText = this.piButtonText;

          temptemData1.onBehalfOf = item.InqCustNme + " " + item.InqCustAddr.InqCustAddrLn1 + " " + item.InqCustAddr.InqCustCty
          + " " + item.InqCustAddr.InqCustSt + " " + item.InqCustAddr.InqCustZip;
          temptemData1.onBehalfOfIsDisputable = false;
          temptemData1.onBehalfOfIsDisputed = false;
          temptemData1.onBehalfOfButtonText = this.piButtonText;

          temptemData1.oPhoneNumber = item.InqCustPhn;
          temptemData1.oPhoneNumberIsDisputable = false;
          temptemData1.oPhoneNumberIsDisputed = false;
          temptemData1.oPhoneNumberButtonText = this.piButtonText;
        }
        else{
          temptemData1.inquirer = item.InqCustNme + " " + item.InqCustAddr.InqCustAddrLn1 + " " + item.InqCustAddr.InqCustCty
                                  + " " + item.InqCustAddr.InqCustSt + " " + item.InqCustAddr.InqCustZip;
          temptemData1.inquirerIsDisputable = true;
          temptemData1.inquirerIsDisputed = false;
          temptemData1.inquirerButtonText = this.piButtonText;
      
          temptemData1.iPhoneNumber = item.InqCustPhn;
          temptemData1.iPhoneNumberIsDisputable = true;
          temptemData1.iPhoneNumberIsDisputed = false;
          temptemData1.iPhoneNumberButtonText = this.piButtonText;

          temptemData1.onBehalfOf = "";
          temptemData1.onBehalfOfIsDisputable = false;
          temptemData1.onBehalfOfIsDisputed = false;
          temptemData1.onBehalfOfButtonText = this.piButtonText;

          temptemData1.oPhoneNumber = "";
          temptemData1.oPhoneNumberIsDisputable = false;
          temptemData1.oPhoneNumberIsDisputed = false;
          temptemData1.oPhoneNumberButtonText = this.piButtonText;
        }
        
        
        temptemData1.address = item.InqAddr.InqAddrLn1 + " " + item.InqAddr.InqCty
                                + " " + item.InqAddr.InqSt + " " + item.InqAddr.InqZip;
        temptemData1.addressIsDisputable = false;
        temptemData1.addressIsDisputed = false;
        temptemData1.addressButtonText = this.piButtonText;
        
        temptemData1.ssnID = this.getMasked(item.InqGovtNbr, 4);
        temptemData1.ssnIDIsDisputable = false;
        temptemData1.ssnIDIsDisputed = false;
        temptemData1.ssnIDButtonText = this.piButtonText;
        
        temptemData1.driverLicense = this.getMasked(item.InqDlNbr, 4);
        temptemData1.driverLicenseIsDisputable = false;
        temptemData1.driverLicenseIsDisputed = false;
        temptemData1.driverLicenseButtonText = this.piButtonText;
    
        temptemData1.state = item.InqDlStCd;
        temptemData1.stateIsDisputable = false;
        temptemData1.stateIsDisputed = false;
        temptemData1.stateButtonText = this.piButtonText;
    
        // for(var item2 of this.disputeRoot.PhnVartnDtl.PhnVartn){
        //   temptemData1.phoneNumbers = temptemData1.phoneNumbers + (temptemData1.phoneNumbers === ""? "" : ",") +item2.PhnNmbr;
        // }
        temptemData1.phoneNumbers = item.InqEvntHmePhn.trim();
        if(item.InqEvntWrkPhn.trim() !== ""){
          temptemData1.phoneNumbers = temptemData1.phoneNumbers + (temptemData1.phoneNumbers === ""? "" : ",") + item.InqEvntWrkPhn.trim();
        }

        temptemData1.phoneNumbersIsDisputable = false;
        temptemData1.phoneNumbersIsDisputed = false;
        temptemData1.phoneNumbersButtonText = this.piButtonText;
    
        temptemData1.email = item.InqEvntEmailAddr;
        temptemData1.emailIsDisputable = false;
        temptemData1.emailIsDisputed = false;
        temptemData1.emailButtonText = this.piButtonText;
    
    
        temptemData1.isSectionDisputed = false;
        temptemData1.isSectionDisputable = true;
        temptemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
    
        tempData.inquiriesViewedByOthersItemList.push(temptemData1);

      }
      
    }

    return tempData;
  }

  initCCIData(){
    var tempData:disputes.checkCashingInquiries;
    tempData = new disputes.checkCashingInquiries();
    for (var item of this.disputeRoot.DisputeEventsList.ChkCshEventDtl.ChkCshEvent) {
      if(item.ChkCshCustNme.trim() !== ""){
        var tempItemData1 = new disputes.checkCashingInquiriesItem();
        tempItemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        tempItemData1.DisputeDataHolder.ChkCshEvent = item;
        tempItemData1.inquirer = item.ChkCshCustNme;
        tempItemData1.inquirerIsDisputable = false;
        tempItemData1.inquirerIsDisputed = false;
        tempItemData1.inquirerButtonText = this.piButtonText;

        tempItemData1.inquiryDate = new Date(item.ChkCshInqDt);
        tempItemData1.inquiryDateIsDisputable = true;
        tempItemData1.inquiryDateIsDisputed = false;
        tempItemData1.inquiryDateButtonText = this.piButtonText;

        tempItemData1.inquiryDollarAmount = Number(item.ChkCshChkAmt);
        tempItemData1.inquiryDollarAmountIsDisputable = false;
        tempItemData1.inquiryDollarAmountIsDisputed = false;
        tempItemData1.inquiryDollarAmountButtonText = this.piButtonText;

        tempItemData1.ssnID = this.getMasked(item.ChkCshGovtNbr, 4);
        tempItemData1.ssnIDIsDisputable = false;
        tempItemData1.ssnIDIsDisputed = false;
        tempItemData1.ssnIDButtonText = this.piButtonText;

        tempItemData1.driverLicense = this.getMasked(item.ChkCshDlNbr, 4);
        tempItemData1.driverLicenseIsDisputable = false;
        tempItemData1.driverLicenseIsDisputed = false;
        tempItemData1.driverLicenseButtonText = this.piButtonText;

        tempItemData1.state = item.ChkCshDlStCd;
        tempItemData1.stateIsDisputable = false;
        tempItemData1.stateIsDisputed = false;
        tempItemData1.stateButtonText = this.piButtonText;

        tempItemData1.isSectionDisputed = false;
        tempItemData1.isSectionDisputable = true;
        tempItemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;

        tempData.checkCashingInquiriesItemList.push(tempItemData1);

      }
      
    }
    
    //------------------------------------------------

    return tempData;
  }

  initRIData(){
    var tempData:disputes.retailInformation;
    tempData = new disputes.retailInformation();
    
    for (var item of this.disputeRoot.DisputeEventsList.RtrnChkEventDtl.RtrnChkEvent) {
      if(item.RtrnChkPrvdrNme.trim() !== ""){
        var tempItemData1 = new disputes.retailInformationItem();
        tempItemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        tempItemData1.DisputeDataHolder.RtrnChkEvent = item;
        tempItemData1.payee = item.RtrnChkPrvdrNme;
        tempItemData1.payeeIsDisputable = false;
        tempItemData1.payeeIsDisputed = false;
        tempItemData1.payeeButtonText = this.piButtonText;

        tempItemData1.amountOfCheck = Number(item.RtrnChkAmt);
        tempItemData1.amountOfCheckIsDisputable = false;
        tempItemData1.amountOfCheckIsDisputed = false;
        tempItemData1.amountOfCheckButtonText = this.piButtonText;

        tempItemData1.dateofCheck = item.RtrnChkDt;
        tempItemData1.dateofCheckIsDisputable = true;
        tempItemData1.dateofCheckIsDisputed = false;
        tempItemData1.dateofCheckButtonText = this.piButtonText;

        tempItemData1.checkNumber = Number(item.RtrnChkNbr);
        tempItemData1.checkNumberIsDisputable = false;
        tempItemData1.checkNumberIsDisputed = false;
        tempItemData1.checkNumberButtonText = this.piButtonText;

        tempItemData1.dateCheckPaid = item.RtrnChkPdDt;
        tempItemData1.dateCheckPaidIsDisputable = false;
        tempItemData1.dateofCheckIsDisputed = false;
        tempItemData1.dateCheckPaidButtonText = this.piButtonText;

        tempItemData1.driverLicense = this.getMasked(item.RtrnChkDlNbr, 4);
        tempItemData1.driverLicenseIsDisputable = false;
        tempItemData1.driverLicenseIsDisputed = false;
        tempItemData1.driverLicenseButtonText = this.piButtonText;

        tempItemData1.state = item.RtrnChkDlStCd;
        tempItemData1.stateIsDisputable = false;
        tempItemData1.stateIsDisputed = false;
        tempItemData1.stateButtonText = this.piButtonText;

        tempItemData1.rtn = item.RtrnChkBnkCd;
        tempItemData1.rtnIsDisputable = false;
        tempItemData1.rtnIsDisputed = false;
        tempItemData1.rtnButtonText = this.piButtonText;

        //tempItemData1.accountNumber = "XXXXXXXXXXXXXXXX1932";
        tempItemData1.accountNumber = this.getMasked(item.RtrnChkAcctNbr, 4);
        tempItemData1.accountNumberIsDisputable = false;
        tempItemData1.accountNumberIsDisputed = false;
        tempItemData1.accountNumberButtonText = this.piButtonText;

        tempItemData1.isSectionDisputed = false;
        tempItemData1.isSectionDisputable = true;
        tempItemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT : this.piButtonText;

        tempData.retailInformatioItemList.push(tempItemData1);

      }
      
    }
    
    //------------------------------------------------

    return tempData;
  }

  initHCOData(){
    var tempData:disputes.historyofChecksOrdered;
    tempData = new disputes.historyofChecksOrdered();
    
    for (var item of this.disputeRoot.DisputeEventsList.ChkOrdEventDtl.ChkOrdEvent) {
      if(item.ChkOrdPrvdrNme.trim() !== ""){
        var tempItemData1 = new disputes.historyofChecksOrderedItem();
        tempItemData1.DisputeDataHolder = new disputes.DisputeDataHolder();
        tempItemData1.DisputeDataHolder.ChkOrdEvent = item;
        tempItemData1.sourceofInformation = item.ChkOrdPrvdrNme + " " + item.ChkOrdPrvdrAddr.ChkOrdPrvdrAddrLn1 + " " + item.ChkOrdPrvdrAddr.ChkOrdPrvdrCty
                                            + " " + item.ChkOrdPrvdrAddr.ChkOrdPrvdrSt + " " + item.ChkOrdPrvdrAddr.ChkOrdPrvdrZip;
        tempItemData1.sourceofInformationIsDisputable = false;
        tempItemData1.sourceofInformationIsDisputed = false;
        tempItemData1.sourceofInformationButtonText = this.piButtonText;

        tempItemData1.dateOrderShipped = new Date(item.ChkOrdShipDt);
        tempItemData1.dateOrderShippedIsDisputable = true;
        tempItemData1.dateOrderShippedIsDisputed = false;
        tempItemData1.dateOrderShippedButtonText = this.piButtonText;

        tempItemData1.phoneNumber = item.ChkOrdPrvdrAddr.ChkOrdPrvdrPhn;
        tempItemData1.phoneNumberIsDisputable = false;
        tempItemData1.phoneNumberIsDisputed = false;
        tempItemData1.phoneNumberButtonText = this.piButtonText;

        tempItemData1.name = item.ChkOrdCnsmrNme.ChkOrdCnsmrFrstNme + " " + item.ChkOrdCnsmrNme.ChkOrdCnsmrMidNme + " " + item.ChkOrdCnsmrNme.ChkOrdCnsmrLstNme;
        tempItemData1.nameIsDisputable = false;
        tempItemData1.nameIsDisputed = false;
        tempItemData1.nameButtonText = this.piButtonText;

        tempItemData1.rtn = item.ChkOrdBnkCd;
        tempItemData1.rtnIsDisputable = false;
        tempItemData1.rtnIsDisputed = false;
        tempItemData1.rtnButtonText = this.piButtonText;

        tempItemData1.accountNumber = this.getMasked(item.ChkOrdAcctNbr, 4);
        tempItemData1.accountNumberIsDisputable = false;
        tempItemData1.accountNumberIsDisputed = false;
        tempItemData1.accountNumberButtonText = this.piButtonText;

        tempItemData1.checkStartNumber = Number(item.ChkOrdChkStrtNbr);
        tempItemData1.checkStartNumberIsDisputable = false;
        tempItemData1.checkStartNumberIsDisputed = false;
        tempItemData1.checkStartNumberButtonText = this.piButtonText;

        tempItemData1.ssnID = this.getMasked(item.ChkOrdGovtNbr, 4);
        tempItemData1.ssnIDIsDisputable = false;
        tempItemData1.ssnIDIsDisputed = false;
        tempItemData1.ssnIDButtonText = this.piButtonText;

        tempItemData1.quantityOrdered = Number(item.ChkOrdQtyOred);
        tempItemData1.quantityOrderedIsDisputable = false;
        tempItemData1.quantityOrderedIsDisputed = false;
        tempItemData1.quantityOrderedButtonText = this.piButtonText;

        tempItemData1.driverLicense = this.getMasked(item.ChkOrdDlNbr, 4);
        tempItemData1.driverLicenseIsDisputable = false;
        tempItemData1.driverLicenseIsDisputed = false;
        tempItemData1.driverLicenseButtonText = this.piButtonText;

        tempItemData1.state = item.ChkOrdDlStCd;
        tempItemData1.stateIsDisputable = false;
        tempItemData1.stateIsDisputed = false;
        tempItemData1.stateButtonText = this.piButtonText;

        tempItemData1.isSectionDisputed = false;
        tempItemData1.isSectionDisputable = true;
        tempItemData1.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT : this.piButtonText;

        tempData.historyofChecksOrderedItemList.push(tempItemData1);

      }
      
    }
    
    //------------------------------------------------

    return tempData;
  }

  disputeRptiClick(code:string, row?: disputes.reportedInformationItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("Reported Information", row.sourceOfInformation);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("Reported Information", "Reported information");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            panelClass: ["fis-style", "custom-dialog"],
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
        }
  }

  disputeIVBOClick(code:string, row?: disputes.inquiriesViewedByOthersItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("Inquiries Viewed by Others", row.inquirerName);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("Inquiries Viewed by Others", "Inquiries viewed by Others");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            panelClass: ["fis-style", "custom-dialog"],
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
    }
  }

  disputeIVBYClick(code:string, row?: disputes.inquiriesViewedByOthersItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("Inquiries Viewed Only by You", row.inquirerName);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("Inquiries Viewed Only by You", "Inquiries Viewed Only by You");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            panelClass: ["fis-style", "custom-dialog"],
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
    }
  }

  disputeCCIClick(code:string, row?: disputes.checkCashingInquiriesItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("Check Cashing Inquiries", row.inquirer);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);     
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("Check Cashing Inquiries", "Check Cashing Inquiries");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            panelClass: ["fis-style", "custom-dialog"],
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
    }
  }


  disputeRIClick(code:string, row?: disputes.retailInformationItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("Retail Information", row.payee);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);    
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("Retail Information", "Retail Information");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            panelClass: ["fis-style", "custom-dialog"],
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
    }
  }


  disputeHCOClick(code:string, row?: disputes.historyofChecksOrderedItem){
    if(this.isDisputeLimitReached() === true){
      return;
    }
    if (!row)
    {
      return;
    }
    else if(row.isSectionDisputed === true){
      return;
    } else if (this.isHumanTrafficking) {
      const result = this.getResultObject("History of Checks Ordered", row.name);
      this.addDisputeDataForSubmit(result, row.DisputeDataHolder, row.uniqueID);      
      row.isSectionDisputed = true;
      row.SectionButtonText = this.piButtonTextAfter;
      this.openSnackBar();
    }
    else{
          const dialogData = new DialogDispute("History of Checks Ordered", "History of Checks ordered");
          const dialogRef = this.dialog.open(DialogdisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            data:dialogData,
            panelClass: ["fis-style", "custom-dialog"],
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              //this.disputeRaisedCount = this.disputeRaisedCount + 1;
              this.addDisputeDataForSubmit(disptResult, row.DisputeDataHolder, row.uniqueID);
              row.isSectionDisputed = true;
              row.SectionButtonText = this.piButtonTextAfter;
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }

           
          });
    }
  }

  addDisputeDataForSubmit(result:any, disputeDataHolder:disputes.DisputeDataHolder, pUniqueID:Guid){
    var tempData: disputes.DisputeDiscloserSubmitData= new disputes.DisputeDiscloserSubmitData();
    tempData.reason = result.reason;
    tempData.type = result.type;
    tempData.description = result.description;
    tempData.DisputeDataHolder = disputeDataHolder;
    tempData.uniqueID = pUniqueID;
    tempData.isHumanTrafficking = this.isHumanTrafficking;
    tempData.data = result.data;
    this.disputeSubmitData.push(tempData);
    this.disputeRaisedCount = this.disputeRaisedCount + 1;
  }

  resetDisputeItemAfterDelete(itemD:disputes.DisputeDiscloserSubmitData){
    this.disputeSubmitData = this.disputeSubmitData.filter(item => item !== itemD);
    this.disputeRaisedCount = this.disputeRaisedCount - 1;
    if(this.cddData.personalInfo.uniqueID === itemD.uniqueID){
      this.cddData.personalInfo.dateOfBirthIsDisputed = false;
      this.cddData.personalInfo.dateOfBirthButtonText = this.piButtonText;
    }
    else{
      this.cddData.personalInfo.DisputeAddress.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.piButtonText;
        }
      });
      this.cddData.personalInfo.DisputeEmailAddress.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.piButtonText;
        }
      });
      this.cddData.personalInfo.DisputeName.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.piButtonText;
        }
      });
      this.cddData.personalInfo.DisputePhone.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.piButtonText;
        }
      });
      this.cddData.reportedInfo.reportedInfoItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
      this.cddData.inquiriesVBO.inquiriesViewedByOthersItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
      this.cddData.checkCashingInq.checkCashingInquiriesItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
      this.cddData.retailInfo.retailInformatioItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
      this.cddData.historyofCO.historyofChecksOrderedItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
      this.cddData.inquiriesVBY.inquiriesViewedByOthersItemList.forEach(element => {
        if(element.uniqueID === itemD.uniqueID){
          element.isSectionDisputed = false;
          element.SectionButtonText = this.isHumanTrafficking ? this.piButtonTextHT :this.piButtonText;
        }
      });
    }
  }

  isDisputeLimitReached(){
    let result:boolean = false;
    if(this.disputeRaisedCount >= 50){
      result = true;
      const dialogMessage = new DialogMessage(
        this._translate.instant(
          'DISPUTETABS.DISPUTELIMITREACHEDTEXT'
        ),
        this._translate.instant(
          'BUTTONS.OK'
        ),
        false,
        this._translate.instant(
          'DISPUTETABS.DISPUTELIMITREACHEDTITLE'
        )
      );
      const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
        panelClass: ["fis-style", "custom-dialog-without-close"],
        data: dialogMessage,
        disableClose: true
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if ((result as string) == 'OK') {
          
        }
      });
    }

    return result;
  }
  resetAllAfterDelete (valu:boolean){
    this.disputeSubmitData.length = 0;
    this.disputeRaisedCount = 0;
  }

  columnsName: any[] = [
    {
      field: 'name',
      name: 'Name',
      title: 'Name',
      width: '25%',
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsAddress: any[] = [
    {
      field: 'address',
      name: 'Address',
      title: 'Address',
      width: '25%',
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsEmail: any[] = [
    {
      field: 'emailAddress',
      name: 'Email',
      title: 'Email',
      width: '25%',
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsPhone: any[] = [
    {
      field: 'phoneNumber',
      name: 'Phone',
      title: 'Phone',
      width: '25%',
    },
    {
      field: 'phntype',
      name: 'Phone Type',
      title: 'Phone Type',
      width: '25%',
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columns: any[] = [
    {
      field: 'sourceOfInformation',
      name: 'Source of Information/Name',
      title: 'Source of information',
      width: '25%',
      sticky: true
    },
    {
      field: 'dateofClosure',
      name: 'Date of Closure',
      width: '25%',
      title: 'Date of closure',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      },
      sticky: true
    },
    {
      field: 'closureStatus',
      name: 'Closure Status',
      title: 'Closure Status',
      width: '25%'
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsIVBO: any[] = [
    {
      field: 'name',
      name: 'Name',
      title: 'Name',
      width: '30%',
      sticky: true
    },
    {
      field: 'inquiryDate',
      name: 'Date',
      width: '30%',
      title: 'Inquiry Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      },
      sticky: true
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsCCI: any[] = [
    {
      field: 'inquirer',
      name: 'Inquirer',
      title: 'Inquirer',
      width: '30%',
      sticky: true
    },
    {
      field: 'inquiryDate',
      name: 'Date',
      width: '30%',
      title: 'Inquiry Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      },
      sticky: true
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsRI: any[] = [
    {
      field: 'payee',
      name: 'Payee',
      title: 'Payee',
      width: '30%',
      sticky: true
    },
    {
      field: 'amountOfCheck',
      name: 'Amount',
      title: 'Amount Of Check',
      width: '30%',
      sticky: true
    },
    {
      field: 'dateofCheck',
      name: 'Date',
      width: '30%',
      title: 'Date of Check',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      },
      sticky: true
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  columnsHCO: any[] = [
    {
      field: 'name',
      name: 'Name',
      title: 'Name',
      width: '30%',
      sticky: true
    },
    {
      field: 'dateOrderShipped',
      name: 'Date',
      width: '30%',
      title: 'Date Order Shipped',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      },
      sticky: true
    },
    {
      field: 'action',
      name: 'Action',
      width: '25%'
    }
  ];

  rowSelectionChange(event: any) {
    //console.log('selection', event);
  }

  onStateChange(event: any) {
    //console.log('state change', event);
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  getDisputeRespData() {
    //let _userName = "ETEST3824";//localStorage.getItem("UserId") || '';
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dispReqRoot:disputes.DisputeRequestRoot = new disputes.DisputeRequestRoot();
    dispReqRoot.UserName = _userName;
    dispReqRoot.RqstHdr = new disputes.RqstHdr();
    dispReqRoot.RqstHdr.RqstTyp = this.isHumanTrafficking ? 'HT' : 'DP';
    dispReqRoot.RqstHdr.RqstSubTyp = 'DR';
    return this.disputeTabsService.DisputeEvents(dispReqRoot);
    //return this.http.get('./././assets/jsondata/DisputeAPI-Events-Retrieval-Rsp.json');
  }

  menuItemsForHT: RufDynamicMenubarItem[] =  
  [
    {
      label: 'Reported Information',
      path: 'ReportedInformation',
    },
    {
      label: 'Inquiries Viewed by Others',
      path: 'InquiriesViewedByOthers',
    },
    {
      label: 'Check Cashing Inquiries',
      path: 'CheckCashingInquiries',
    },
    {
      label: 'Inquiries Viewed Only by You',
      path: 'InquiriesViewedByYou',
    },
    {
      label: 'Retail Information',
      path: 'RetailInformation',
    },
    {
      label: 'History of Checks Ordered',
      path: 'HistoryofChecksOrdered',
    }    
  ];
  
  menuItemsForDispute: RufDynamicMenubarItem[] = [
    {
      label: 'Personal Information',
      path: 'PersonalInformation',
    },
    {
      label: 'Reported Information',
      path: 'ReportedInformation',
    },
    {
      label: 'Inquiries Viewed by Others',
      path: 'InquiriesViewedByOthers',
    },
    {
      label: 'Check Cashing Inquiries',
      path: 'CheckCashingInquiries',
    },
    {
      label: 'Inquiries Viewed Only by You',
      path: 'InquiriesViewedByYou',
    },
    {
      label: 'Retail Information',
      path: 'RetailInformation',
    },
    {
      label: 'History of Checks Ordered',
      path: 'HistoryofChecksOrdered',
    }    
  ];
  openbasic(event: any) {
    this.selectedPath = event.path;    
  }

  getMasked(value: string, visibleCharCount: number){
    var tempstr:string = "";
    var result:string = "";
    for (let i = 0; i < (value.length-visibleCharCount); i++) {
      tempstr = tempstr + "X";
    }
    result = tempstr + value.slice(value.length - visibleCharCount);
    return result;
  }

  private ConvertPhoneFormate(phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(",", 3);
    return (formatedPhoneNumber[0] + formatedPhoneNumber[1] + formatedPhoneNumber[2]);
  }

  createSnackBarConfig() {
    const sposition = this.position.split('-');
    const config = new MatSnackBarConfig();
    config.duration = 60000;
    config.panelClass = this.panelClass;
    config.verticalPosition = <MatSnackBarVerticalPosition>sposition[0];
    config.horizontalPosition = <MatSnackBarHorizontalPosition>sposition[1];
    return config;
  }
  openSnackBar() {
    const config = this.createSnackBarConfig();
    config.data = this._translate.instant(
      'DISPUTETABS.SNACKBARADDHUMANTRAFFICKING'
    );
    //config.data = "The selected item has been added to your dispute. You can continue to add additional items or click ‘Review’ to submit the request to ChexSystems.";
    this.snackBar.openFromComponent(CommonSnackbarComponent, config);
  }
  getResultObject(type: string, eventData: string): any {
    const disputeResult = {
      description : "[null]",
      reason : "Human Trafficking Block",
      type : type,
      data : eventData
      }
      return disputeResult;
  }
}
