import { Injectable } from '@angular/core';
import { loggerModel } from '../../../_models/classes/common-logger.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../shared/api.service';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';


@Injectable({
  providedIn: 'root'
})
export class CommonLoggerService {

  private readonly Api_Base_URL = environment.loggerApiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
   
  }

/**
   * get the freeze details
   * @param lfRequest
   * @returns response of api
   */
 logError(logErrRequest:loggerModel.ErrorRequest):Observable<any>{
    const url = this.Api_Base_URL.concat('/logerror');
    return this._apiService.post<any>(url,logErrRequest);
    // .pipe(      
    //   catchError(this.handleError)
    // );
  }

  logInfo(logInfoRequest:loggerModel.InfoRequest):Observable<any>{
    const url = this.Api_Base_URL.concat('/loginfo');
    return this._apiService.post<any>(url,logInfoRequest);
    // .pipe(      
    //   catchError(this.handleError)
    // );
  }
 
  private handleError(error: HttpErrorResponse) {
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

}
