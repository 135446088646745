<ruf-dialog-panel  [ngClass]="{'custom_100vh':isHumantrafficking }" rufId="dialog_without_header">
    <ruf-dialog-header role="region" aria-label="header" class="Header">{{disputeCount}} {{isHumantrafficking ? 'Block': 'Dispute'}}</ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content">
    <!-- <div mat-subheader>Dispute</div> -->
    <div class="chex-dlg-disclosure-divider"><mat-divider></mat-divider></div>
    <div class="Data" *ngFor="let data of disputeData" >
        <div fxLayout="column" fxLayout.gt-sm="row" rufMarginVertical rufId >
            <div class="chex-dlg-disclosure-col-container">
            
                <div fxLayout="row" rufId fisStyle class="chex-dlg-disclosure-col">
                    <table>
                        <tr> 
                            <td class="chex-dlg-disclosure-col-1-td">
                                <div id="typetxt" role="region" aria-label="type" class="chex-dlg-disclosure-label mat-subheading-1 " >{{ 'DIALOGDISCLOSURE.TYPE' | translate }}</div>
                            </td>
                            <td class="chex-dlg-disclosure-col-2-td">
                                <span role="region" aria-label="typedata" class=" mat-subheading-1" >{{data.type}}</span>
                            </td>
                        </tr>
                    </table>
                </div>

                 <div fxLayout="row" rufId fisStyle class="chex-dlg-disclosure-col">
                    <table>
                        <tr> 
                            <td class="chex-dlg-disclosure-col-1-td">
                                <div role="region" aria-label="reason" id="reasontxt" class="chex-dlg-disclosure-label mat-subheading-1 " >{{ 'DIALOGDISCLOSURE.REASON' | translate }}</div>
                            </td>
                            <td class="chex-dlg-disclosure-col-2-td">
                                <span role="region" aria-label="reasondata" class=" mat-subheading-1" >{{data.reason}}</span>
                            </td>
                        </tr>
                    </table>
                </div>

                 <div fxLayout="row" rufId fisStyle class="chex-dlg-disclosure-col" *ngIf="data.isHumanTrafficking">
                    <table>
                        <tr> 
                            <td class="chex-dlg-disclosure-col-1-td">
                                <div id="typetxt" role="region" aria-label="Inquirer/SourceOfInfo/Payee" class="chex-dlg-disclosure-label mat-subheading-1 " >{{ 'DIALOGDISCLOSURE.INQUIRER' | translate }}</div>
                            </td>
                            <td class="chex-dlg-disclosure-col-2-td">
                                <span role="region" aria-label="typedata" class=" mat-subheading-1" >{{data.data}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
               
                <div fxLayout="row" rufId fisStyle class="chex-dlg-disclosure-col" *ngIf="!data.isHumanTrafficking">
                    <table>
                        <tr> 
                            <td class="chex-dlg-disclosure-col-1-td">
                                <div role="region" aria-label="description" id="descriptiontxt" class="chex-dlg-disclosure-label mat-subheading-1" >{{ 'DIALOGDISCLOSURE.DESCRIPTION' | translate }}</div>
                            </td>
                            <td class="chex-dlg-disclosure-col-2-td">
                                <span role="region" aria-label="descdata" class=" mat-subheading-1" >{{data.description}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="chex-dlg-disclosure-icon" >
                <a id="delBtn" href="javascript:void(0);">
                 <button (click)="deleteItem(data)" class="del" mat-button fisStyle><ruf-labeled-icon fontIcon="fis-icon-trash"></ruf-labeled-icon></button>
                </a>
            </div>
        </div>
        <div class="divider"><mat-divider></mat-divider></div>
    </div>
    <div class="chex-dlg-disclosure-divider-ht" *ngIf="isHumantrafficking"><mat-divider></mat-divider></div>
    <chexsystem-ws-dialog-file-uploader *ngIf="isHumantrafficking" [ishumantrafficking]="isHumantrafficking" (closeDialog)="closeSummaryDialog()">  </chexsystem-ws-dialog-file-uploader>
    
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer" *ngIf="!isHumantrafficking">
        <div role="region" aria-label="buttons" class="chex-dlg-disclosure-center">
            <button id="submitBtn" mat-raised-button fisStyle color="primary" class="form_button" rufId="dialog_footer_button" (click)="onSubmit()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
            <button id="cancelBtn" mat-stroked-button fisStyle color="primary" class="form_button" rufId (click)="onCancel()">{{ 'BUTTONS.CANCEL' | translate }}</button>
        </div>
    </ruf-dialog-footer>

</ruf-dialog-panel>
<!-- <chexsystem-ws-dialog-file-uploader *ngIf="isHumantrafficking" [ishumantrafficking]="isHumantrafficking" (closeDialog)="closeSummaryDialog()">  </chexsystem-ws-dialog-file-uploader> -->


