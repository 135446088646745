export namespace loggerModel{

  export class ErrorRequest {
    error: string
    errorcode: string
    userName: string
    govtNBR: string
  }

  export class InfoRequest {
    Info: string
    Infocode: string
  }

}