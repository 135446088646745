import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { CommonLoggerService } from '../shared/logger-apis/logger-apis.service';
import { loggerModel } from '../../_models/classes/common-logger.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler  {

  

  constructor(private _logger: CommonLoggerService) {
   }

   handleError(error: any) {
    if ((error instanceof HttpErrorResponse)) {
      //console.error(`${this.formatDate(new Date())} ${error.error.statusCode}  ${error.error.message}`);
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${error.error.statusCode}  ${error.error.message}`;
          logReq.errorcode = error.error.statusCode;
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
    }
    else{
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())}` + " " + error;
          logReq.errorcode = "";
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
      //console.error(`${this.formatDate(new Date())}`, error);
    }
  }
  
  handleErrorEx(error: any, sourceMethod:string | undefined, req:any) {
    if ((error instanceof HttpErrorResponse)) {
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${sourceMethod} ${error.error.statusCode}  ${error.error.message}`;
          logReq.errorcode = error.error.statusCode;
          if (sourceMethod === "session" || sourceMethod === "ida-questions" ||
          (sourceMethod === "users" && req.method ==="POST")) {
            const username = req?.body?.firstName ? req?.body?.firstName+ "_" + req?.body?.lastName :
            req?.body?.dsUserObj?.UserRegistnReq?.CnsmrPrsnlDetls?.CnsmrNmDetls?.FrstNm + "_"+ req?.body?.dsUserObj?.UserRegistnReq?.CnsmrPrsnlDetls?.CnsmrNmDetls?.LastNm;
            logReq.userName= encodeURIComponent(username);
           } else if(sourceMethod === "LookupFreezeForReg" || sourceMethod === "SrchFrzExistenceForReg") {
           logReq.govtNBR = encodeURIComponent(req.body.GovtNbr);
           } else if(sourceMethod === "ValidateSecurityAlertForReg") {
            logReq.govtNBR = encodeURIComponent(req.body.govtNbr);
           } else if(sourceMethod === "ViewSecurityAlertForReg") {
            logReq.govtNBR = encodeURIComponent(req?.body?.viewSecurityAlertRequest?.govtNbr);
           }
           else if(sourceMethod === "users" && req.method ==="PUT") {
            logReq.userName= encodeURIComponent(req.body.loginName);
           } 
           if(!(sourceMethod === "profilepic")) {
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      ); }
      //console.error(`${this.formatDate(new Date())} ${sourceMethod} ${error.error.statusCode}  ${error.error.message}`);
    }
    else{
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${sourceMethod}` + " " + error;
          logReq.errorcode = "";
          if (sourceMethod === "session" || sourceMethod === "ida-questions" ||
          (sourceMethod === "users" && req.method ==="POST")) {
            const username = req?.body?.firstName ? req?.body?.firstName+ "_" + req?.body?.lastName :
            req?.body?.dsUserObj?.UserRegistnReq?.CnsmrPrsnlDetls?.CnsmrNmDetls?.FrstNm + "_"+ req?.body?.dsUserObj?.UserRegistnReq?.CnsmrPrsnlDetls?.CnsmrNmDetls?.LastNm;
            logReq.userName= encodeURIComponent(username);
           } else if(sourceMethod === "LookupFreezeForReg" || sourceMethod === "SrchFrzExistenceForReg") {
           logReq.govtNBR = encodeURIComponent(req.body.GovtNbr);
           } else if(sourceMethod === "ValidateSecurityAlertForReg") {
            logReq.govtNBR = encodeURIComponent(req.body.govtNbr);
           } else if(sourceMethod === "ViewSecurityAlertForReg") {
            logReq.govtNBR = encodeURIComponent(req?.body?.viewSecurityAlertRequest?.govtNbr);
           }
           else if(sourceMethod === "users" && req.method ==="PUT") {
            logReq.userName= encodeURIComponent(req.body.loginName);
           } 
           if(!(sourceMethod === "profilepic")) {
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
           }
      //console.error(`${this.formatDate(new Date())} ${sourceMethod}`, error);
    }
    
  }

  formatDate(date:Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    )
  }

  padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
  }
}
