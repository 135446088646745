import { Injectable } from '@angular/core';
import { scoreReq } from '../../_models/classes/consumer-score-disclosure-request.model';
import { scoreRes } from '../../_models/classes/consumer-score-disclosure-response.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ApiService } from '../shared/api.service';

@Injectable({
  providedIn: 'root'
})

export class ConsumerScoreDisclosureService {

  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
   
  }

  /**
   * get the freeze details
   * @param scoreRequest
   * @returns response of api
   */
   getConsumerDisclosureScore(scoreRequest: scoreReq.consumerScoreData):Observable<scoreRes.consumerScoreDisclosure>{
    const url = this.Api_Base_URL.concat('/ConsumerScore');
    return this._apiService.post<scoreRes.consumerScoreDisclosure>(url,scoreRequest);
    // .pipe(      
    //   catchError(this.handleError)
    // );
   }

  // getConsumerDisclosureScore(scoreRequest: consumerScoreData)
  // {
  //   let consumerDisclosureCount: consumerScoreDisclosure = {
  //       RqstHdr: {
  //         RqstTyp: "CS",
  //         RqstSubTyp: "CSR"
  //       },
  //       CnsmrScrRqst: {
  //         CnsmrRefId: "CXCS71892",
  //         CnsmrPrsnlDetls: {
  //           ChexConsId: "",
  //           CnsmrNmDetls: {
  //             FrstNm: "Gendle",
  //             MidNm: "",
  //             LastNm: "Gellowson",
  //             SuffixNm: "",
  //             MaidenNm: ""
  //           },
  //           BirthDt: "07/24/1962",
  //           GovtNbr: "491712450",
  //           CnsmrDrivLicenseDetls: {},
  //           PhoneNbr: "",
  //           Email: "testing@fislgobal.com"
  //         },
  //         CnsmrAddrDetls: {
  //           CnsmrAddr: {
  //             AddrLine1: "5507 23rd St",
  //             AddrLine2: "",
  //             CityNm: "Woodbury",
  //             StatCode: "MN",
  //             ZipCode: "55125"
  //           },
  //           CnsmrPreviousAddr1: {
  //             AddrLine1Nm: "",
  //             AddrLine2Nm: "",
  //             CityNm: "",
  //             StatCode: "",
  //             ZipCode: ""
  //           },
  //           CnsmrPreviousAddr2: {
  //             AddrLine1Nm: "",
  //             AddrLine2Nm: "",
  //             CityNm: "",
  //             StatCode: "",
  //             ZipCode: ""
  //           },
  //           CnsmrPreviousAddr3: {
  //             AddrLine1Nm: "",
  //             AddrLine2Nm: "",
  //             CityNm: "",
  //             StatCode: "",
  //             ZipCode: ""
  //           }
  //         },
  //         CnsmrScoreDetls: {
  //           DebtBurScr: "9999",
  //           DebtBurResnCde: {
  //             DebtBurResnCde1: "Z3",
  //             DebtBurResnCde2: "",
  //             DebtBurResnCde3: "",
  //             DebtBurResnCde4: "",
  //             DebtBurResnCde5: ""
  //           },
  //           DebtBurResnTxt: {
  //             DebtBurResnTxt1: "INSUFFICIENT DATA FOUND",
  //             DebtBurResnTxt2: "",
  //             DebtBurResnTxt3: "",
  //             DebtBurResnTxt4: "",
  //             DebtBurResnTxt5: ""
  //           }
  //         },
  //         CnsmrRequestCodes: {
  //           RequestSrcCode: "CaseVue",
  //           RequestTypCode: "ConsumerScore",
  //           CommentTxt: "This is testing"
  //         },
  //         RecvdDtTm: "2020-12-29T07:43:06"
  //       },
  //       RspCd: "200",
  //       RspMsg: "Successful Operation"
  //     };
  //   return consumerDisclosureCount;
  // }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

}
