
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { disputereasons } from '../../_models/classes/dispute-reasons.model';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CommonSnackbarComponent } from '../../common/common-snackbar/common-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { FormValidation } from '../../common/validation-common/form-validator';
import { RufDropdownType } from '@ruf/shell';

export class DialogDispute{
  constructor(public section:string, public category:string){
  } 
}
@Component({
  selector: 'chexsystem-ws-dialogdispute',
  templateUrl: './dialog-dispute.component.html',
  styleUrls: ['./dialog-dispute.component.scss']
})
export class DialogdisputeComponent implements OnInit {

  formGroup: FormGroup;
  section:string ='';
  disputeType:string;
  disputeReason:string;
  comments:string;
  panel = { type: RufDropdownType.PopoverMenu };
  hasBackdrop = true;
  //reasonList: string[] = ["Reason1", "Reason2"];
  disputeReasonData:disputereasons.DisputeReasons;
  reasonList:disputereasons.ReasonList[];
  position: string = 'top-center';
  panelClass = ['fis-style', 'ruf-info', 'ruf-inkbar-bottom', 'chex-common-dispute-snackbar'];

  
  constructor(public http: HttpClient,
    private _formBuilder: FormBuilder, 
    private snackBar: MatSnackBar,
    private _translate: TranslateService,
    public dialogRef: MatDialogRef<DialogdisputeComponent>,
    @Inject(MAT_DIALOG_DATA) public Data:DialogDispute)
    { 
      this.getReasonData().subscribe((res) => {
        this.disputeReasonData = res as disputereasons.DisputeReasons;
        for (let entry of this.disputeReasonData.CategoryList) {
          if(entry.CategoryName === Data.category){
            this.reasonList = entry.ReasonList;
          }
        }
        //this.reasonList = this.disputeReasonData.CategoryList[category].ReasonList;
      });
      this.section = Data.section;
      this.formGroup = this._formBuilder.group({
        reasonctrl: ['',Validators.required],
        textAreactrl: ['']
      },
      {
        validators: [
          FormValidation.CommentTextValidation('textAreactrl')]
    });
    }

  ngOnInit(): void {
  }

  onSubmit(): void{
    if(this.formGroup.valid){
      this.disputeReason = this.formGroup.get('reasonctrl')?.value;
      var dialogDisputeResult:DialogDisputeResult = new DialogDisputeResult();
      dialogDisputeResult.description = (this.comments!== undefined) ? this.comments: "[null]";
      dialogDisputeResult.reason = this.disputeReason;
      dialogDisputeResult.result = "OK";
      dialogDisputeResult.type = this.section;
      this.dialogRef.close(dialogDisputeResult);
      this.openSnackBar();
    }
    else{
      this.formGroup.markAllAsTouched();
    }
  }

  onCancel(): void{
    this.dialogRef.close('cancel');
  }
  getReasonData() {
    return this.http.get('./././assets/jsondata/dispute-reasons.json');
  }

  createSnackBarConfig() {
    const sposition = this.position.split('-');
    const config = new MatSnackBarConfig();
    config.duration = 60000;
    config.panelClass = this.panelClass;
    config.verticalPosition = <MatSnackBarVerticalPosition>sposition[0];
    config.horizontalPosition = <MatSnackBarHorizontalPosition>sposition[1];
    return config;
  }

  openSnackBar() {
    const config = this.createSnackBarConfig();
    config.data = this._translate.instant(
      'DISPUTETABS.SNACKBARADDDISPUTE'
    );
    //config.data = "The selected item has been added to your dispute. You can continue to add additional items or click ‘Review’ to submit the request to ChexSystems.";
    this.snackBar.openFromComponent(CommonSnackbarComponent, config);
  }

}

export class DialogDisputeResult{
  type:string;
  reason:string;
  description: string;
  result:string;
  data:string;
}
