import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ApiService } from '../shared/api.service';
import { disputes } from '../../_models/classes/consumer-discloser-dispute.model';
import { disputesWithOutAttachRes } from '../../_models/classes/dispute-without-attachment-res.model';
import { disputesWithOutAttachReq } from '../../_models/classes/dispute-without-attachment-req.model';
import { disputesWithAttachRes } from '../../_models/classes/dispute-with-attachment-res.model';
import { disputesWithAttachReq } from '../../_models/classes/dispute-with-attachment-req.model';
import { Multer } from 'multer';
import { Express } from 'express';
//import * as FormData  from 'form-data';
import FormData from "form-data"
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Used to interact with ida endpoint
 */
export class DisputeTabsService{
  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
    this.resetAllAfterDeleteEvent$ = new EventEmitter();
    this.resetDisputeItemAfterDeleteEvent$ = new EventEmitter();
  }
  
  public resetAllAfterDeleteEvent$: EventEmitter<boolean>;
  public resetDisputeItemAfterDeleteEvent$: EventEmitter<disputes.DisputeDiscloserSubmitData>;
  public triggerResetAllAfterDelete(): void {
    this.resetAllAfterDeleteEvent$.emit(true);
  }
  public triggerResetDisputeItemAfterDelete(itemD:disputes.DisputeDiscloserSubmitData): void {
    this.resetDisputeItemAfterDeleteEvent$.emit(itemD);
  }


  /**
   * get disputeitem
   * @param diRequest
   * @returns response of api
   */
   DisputeEvents(diRequest:disputes.DisputeRequestRoot):Observable<disputes.DisputeRoot>{
    const url = this.Api_Base_URL.concat('/DisputeEvents');
    return this._apiService.post<disputes.DisputeRoot>(url,diRequest);
  }

  /**
   * get disputeitem
   * @param diRequest
   * @returns response of api
   */
   disputewithnoattachment(diRequest:disputesWithOutAttachReq.DisputeRequestRoot):Observable<disputesWithOutAttachRes.DisputeResponseRoot>{
    const url = this.Api_Base_URL.concat('/disputewithnoattachment');
    return this._apiService.post<disputesWithOutAttachRes.DisputeResponseRoot>(url,diRequest);
  }

  /**
   * get disputeitem
   * @param diRequest
   * @returns response of api
   */
   disputewithattachment(attachments: Array<Express.Multer.File>, diRequest:disputesWithAttachReq.DisputeRequestRoot):Observable<disputesWithAttachRes.DisputeResponseRoot>{
    const url = this.Api_Base_URL.concat('/disputewithattachment');
    let formdata = new FormData();

    formdata.append('disputeRequest', JSON.stringify(diRequest));
    attachments.forEach(file => {
      formdata.append('attachments',file, file.originalname );
    });  
    
    return this._apiService.postFile<disputesWithAttachRes.DisputeResponseRoot>(url, formdata);
  }
 

  

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

  
}
