import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  Input,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';

@Component({
  selector: 'chx-footer',
  templateUrl: './chx-footer.component.html',
  styleUrls: ['./chx-footer.component.scss'],
})


export class ChxFooterComponent {

  
  educationURLBeforeLogin: string = environment.educationURLBeforeLogin;
  consumerDisclosureURL: string = environment.consumerDisclosureURL;
  consumerScoreURL: string = environment.consumerScoreURL;
  identityTheftInformationURL: string = environment.identityTheftInformationURL;
  securityAlertURL: string = environment.securityAlertURL;
  securityFreezeInformationURL: string = environment.securityFreezeInformationURL;
  placeaFreezeURL: string = environment.placeaFreezeURL;
  replacementPINURL: string = environment.replacementPINURL;
  liftFreezeURL: string = environment.liftFreezeURL;
  rightsByStateURL: string = environment.rightsByStateURL;
  disputeURL: string = environment.disputeURL;
  infoURL: string = environment.infoURL;
  privacyPolicyURL: string = environment.privacyPolicyURL;
  legalNoticesURL: string = environment.legalNoticesURL;
  contactUsURL: string = environment.contactUsURL;
  aboutUsURL: string = environment.aboutUsURL;
  info1=this._translate.instant('FOOTERS.ABOUT US');
  copyright=this._translate.instant('FOOTERS.COPYRIGHT');
  info2=this._translate.instant('FOOTERS.CONTACT US');
  copyright1=this._translate.instant('FOOTERS.COPYRIGHT');
  menuState:boolean = true;

  _isLoggedIn:boolean;
  @Input()
  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }
  set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }
  copyrightYear: number;
  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService,
  ){
    this.copyrightYear = new Date().getFullYear();
    piConfirmService.confirmPIEvent$.subscribe(item => this.setMenuState(item));
  }

  navigateToLinks(code:string){

    if(code === "RequestReports"){
      //this.router.navigate( ['pdf-view-docs', {src: "disclosure", id: 23343432432}]);
      if(this.isLoggedIn){
        this.router.navigate( ['disclosure-report-info']);
      }
      else{
        let winRef = window.open(this.consumerDisclosureURL + "/#header","_self");
      }
    }
    else if(code === "IdentityTheft"){
      if(this.isLoggedIn){
        this.router.navigate( ['identity-theft-info']);
      }
      else{
        let winRef = window.open(this.identityTheftInformationURL + "/#header","_self");
      }
      
    }
    else if(code === "SecurityFreeze"){
      if(this.isLoggedIn){
        this.router.navigate( ['security-freeze-info']);
      }
      else{
        let winRef = window.open(this.securityFreezeInformationURL + "/#header","_self");
      }
      
      
    }
    else if(code === "Education"){
      if(this.isLoggedIn){
        let winRef = window.open(this.educationURLBeforeLogin, '_blank','noopener,noreferrer');
      }
      else{
        let winRef = window.open(this.educationURLBeforeLogin,"_self");
      }
      
    }
    else if(code === "Dispute"){
      if(this.isLoggedIn){
        this.router.navigate( ['discloser-dispute-trans']);
      }
      else{
        let winRef = window.open(this.disputeURL + "/#header","_self");
      }
    }
    else if(code === "AboutUs"){
      if(this.isLoggedIn){
        this.router.navigate( ['about-us']);
      }
      else{
        let winRef = window.open(this.aboutUsURL + "/#header","_self");
      }
      
    }
    else if(code === "ContactUs"){
      if(this.isLoggedIn){
        this.router.navigate( ['contact-us']);
      }
      else{
        let winRef = window.open(this.contactUsURL + "/#header","_self");
      }
      
    }
    else if(code === "LegalNotices"){
      if(this.isLoggedIn){
        let winRef = window.open(this.legalNoticesURL + "/#header", '_blank','noopener,noreferrer');
      }
      else{
        let winRef = window.open(this.legalNoticesURL + "/#header","_self");
      }
      
    }
    else if(code === "PrivacyPolicy"){
      if(this.isLoggedIn){
        let winRef = window.open(this.privacyPolicyURL + "/#header", '_blank','noopener,noreferrer'); 
      }
      else{
        let winRef = window.open(this.privacyPolicyURL + "/#header","_self");
      }
      
    }
    else if(code === "Info"){
      if(this.isLoggedIn){
        let winRef = window.open(this.infoURL + "/#header", '_blank','noopener,noreferrer');
      }
      else{
        let winRef = window.open(this.infoURL + "/#header","_self");
      }
      
    }

  }

  setMenuState(val:boolean){
    this.menuState = val;
  }

  }