import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DecisionSolutionUserCreationRequest, DecisionSolutionUserResponse } from '../../_models/registration/DecisionSolutionUserDetail';
import { IdaQuizAnswers, IdaQuizQuestion } from '../../_models/registration/idaquizquestion.model';
import { Ans, IdaDecisionRequest, IdaDecisionResponse, IdaQuizMockAnswer, Quiz, QuizRequest } from '../../_models/registration/Quiz';
import { Registrationresource } from '../../_models/registration/registrationresource.model';
import { UserDetail, UserLookupRequest } from '../../_models/registration/UserDetail';
import { ApiService } from '../shared/api.service';

/**
 * Used to interact with ida endpoint
 */
export interface IIdaQuizService {
  GetIdaQuizQuestions(quizRequest: QuizRequest): void;
}

@Injectable({
  providedIn: 'root'
})

/**
 * Used to interact with ida endpoint
 */
export class IdaQuizService implements IIdaQuizService {
  quizAnswerKey: IdaQuizAnswers[] = [];
  private readonly Api_Base_URL = environment.apiurl;
  idaQuizMockAnswers: IdaQuizMockAnswer[];
  constructor(private httpClient: HttpClient, private _apiService: ApiService) {
    
    if(Registrationresource.IsMockIdaQuiz){
      this.getAnswerJson().subscribe(answer => {
        this.quizAnswerKey = answer as IdaQuizAnswers[];
      });
      this.GetIdaQuizMockAnswers().subscribe((idaQuizMockAnswer) => {
        this.idaQuizMockAnswers = idaQuizMockAnswer;
      })
    }
  }

  /**
   * Validate the user answers
   * @param quizAnswer submitted answer
   * @returns Answered count
   */
  ValidateTheAnswers(quizAnswer: IdaQuizAnswers[]): any {
    let answerCount = this.validateTheAnswer(this.quizAnswerKey, quizAnswer);
    return answerCount;
  }

  /**
   * Gets Ida quiz questions
   * @param quizRequest
   * @returns Ida Quiz Questions
   */
  GetIdaQuizQuestions(quizRequest: QuizRequest): Observable<IdaQuizQuestion[]> {
    const url = this.Api_Base_URL.concat('/ida-questions');
    this._apiService.post<IdaQuizQuestion>(url, quizRequest).subscribe(res => {
    });
    //return this._apiService.post<IdaQuizQuestion>(url,quizRequest);
    return this.httpClient.get<IdaQuizQuestion[]>("../../../assets/jsondata//idaquizquestions.json");
  }

  /**
   * Gets Ida quiz questions
   * @param quizRequest
   * @returns Ida Quiz Questions
   */
  FetchIdaQuizQuestions(quizRequest: QuizRequest, catpchaToken: string): Observable<Quiz> {
    if (Registrationresource.IsMockIdaQuiz) {
      return this.httpClient.get<Quiz>("../../../assets/jsondata//idaquestions.json");
    }else{
      const url = this.Api_Base_URL.concat('/ida-questions');
      return this._apiService.postWithCustomHeader<Quiz>(
        url, 
        quizRequest,
        catpchaToken);
    }
    // return this._apiService.post<Quiz>(
    //     url, 
    //     quizRequest);
    // }
  }

  /**
   * Validate IDA answers
   * @param decisionRequest IDA-Decision request 
   * @returns IDA-Decision response for Pass, Fail, Challenge
   */
  idaDecisionValidate(decisionRequest: IdaDecisionRequest, captchaToken: string): Observable<IdaDecisionResponse> {
    if (Registrationresource.IsMockIdaQuiz) {
      let answerCount: number = 0;
      let idaQuizAnswers = this.FilterIdaQuizAnswers(decisionRequest.ans);
      this.idaQuizMockAnswers.forEach(function (quiz) {
        if (quiz.quizId == decisionRequest.ans.quizId && quiz.transId == decisionRequest.ans.transId) {
          let difference = quiz.answerResponseIds.filter(x => idaQuizAnswers.includes(x));
          answerCount = difference.length;
        }
      });
      let idaMockValidate = this.GetIdaQuizMockValidateResponse();
      idaMockValidate.score = answerCount;
      if(decisionRequest.ans.quizId == "DISC:PEN:2147630184" && decisionRequest.ans.transId == "4270132154"){
        if (answerCount >= 4) {
          return this.httpClient.get<IdaDecisionResponse>("../../../assets/jsondata/idaquizmockpass.json");
        } else if (answerCount == 3) {
          return this.httpClient.get<IdaDecisionResponse>("../../../assets/jsondata/quizchallenge.json");
        } else {
          return this.httpClient.get<IdaDecisionResponse>("../../../assets/jsondata/idaquizmockfail.json");
        }
      }else  // if(decisionRequest.ans.quizId == "DISC:PEN:2147630186" && decisionRequest.ans.transId == "4270160002")
      {
        if (answerCount == 2) {
          return this.httpClient.get<IdaDecisionResponse>("../../../assets/jsondata/idaquizmockpass.json");
        } else {
          return this.httpClient.get<IdaDecisionResponse>("../../../assets/jsondata/idaquizmockfail.json");
        }
      }      
    } else {
      const url = this.Api_Base_URL.concat('/ida-decision');
      return this._apiService.postWithCustomHeader<IdaDecisionResponse>(
        url, 
        decisionRequest,
        captchaToken);
    }
  }
  /**
   * Gets Ida answer keys
   * @returns Answer keys
   */
  getAnswerJson() {
    return this.httpClient.get("../../../assets/jsondata/idaquizanswers.json");
  }

  /**
   * Gets challenge quiz
   * @returns Challenge quiz questions
   */
  getChallengeQuiz(): Observable<IdaQuizQuestion[]> {
    return this.httpClient.get<IdaQuizQuestion[]>("../../../assets/jsondata/quizchallenge.json");
  }

  /**
   * 
   * @param quizAnswerKey Answer keys
   * @param quizAnswer User submitted answer
   * @returns Answer count
   */
  private validateTheAnswer(quizAnswerKey: IdaQuizAnswers[], quizAnswer: IdaQuizAnswers[]): number {
    let answerCount: number = 0;
    quizAnswerKey.forEach(key => {
      quizAnswer.forEach(answer => {
        if (key.questionId === answer.questionId && key.answerId === answer.answerId)
          ++answerCount;
      });
    });
    return answerCount;
  }

  public getUserDetailFromDecisionSolution(lookupRequest: UserLookupRequest): Observable<UserDetail> {
    const url = this.Api_Base_URL.concat('/UserLookup');
    return this._apiService.post<UserDetail>(url, lookupRequest);
  }

  // public CreateUserInDecisionSolution(userDetail: DecisionSolutionUserCreationRequest, captchaToken:any): Observable<DecisionSolutionUserResponse> {
  //   const url = this.Api_Base_URL.concat('/UserRegistration');
  //   return this._apiService.postWithCustomHeader<DecisionSolutionUserResponse>(url, userDetail,captchaToken)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  public UpdateUserInDecisionSolution(userDetail: DecisionSolutionUserCreationRequest): Observable<DecisionSolutionUserResponse> {
    const url = this.Api_Base_URL.concat('/UpdateUserProfile');
    return this._apiService.post<DecisionSolutionUserResponse>(url, userDetail);
  }


  /**
   public CreateUser(userRequest:IdpUser):Observable<UserDetail>{
    const url = this.Api_Base_URL.concat('/users');
    return this._apiService.post<UserDetail>(url,userRequest)
    .pipe(      
      catchError(this.handleError)
    );
  }
   */
  private handleError(error: HttpErrorResponse) {
    let errorMessage: string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

  private GetIdaQuizMockAnswers(): Observable<IdaQuizMockAnswer[]> {
    return this.httpClient.get<IdaQuizMockAnswer[]>("../../../assets/jsondata/idaquizmockanswers.json");
  }

  private FilterIdaQuizAnswers(answers: Ans): string[] {
    let idaQuizAnswers: string[] = new Array();
    if (answers.answer1QuestionId != undefined)
      idaQuizAnswers.push(answers.answer1ResponseId);
    if (answers.answer2QuestionId != undefined)
      idaQuizAnswers.push(answers.answer2ResponseId);
    if (answers.answer3QuestionId != undefined)
      idaQuizAnswers.push(answers.answer3ResponseId);
    if (answers.answer4QuestionId != undefined)
      idaQuizAnswers.push(answers.answer4ResponseId);
    if (answers.answer5QuestionId != undefined)
      idaQuizAnswers.push(answers.answer5ResponseId);
    return idaQuizAnswers;
  }

  private GetIdaQuizMockValidateResponse(): IdaDecisionResponse {
    let validateResponse: IdaDecisionResponse = {
      base: 7,
      decision: "Pass",
      idaOid: "2147630212",
      idaQuiz: undefined,
      score: 5,
      userReferenceText1: null,
      userReferenceText2: null,
      userReferenceText3: null,
      errorCd:'',
      errorTxt:''
    };
    return validateResponse;
  }
}
