import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { disputes } from '../../_models/classes/consumer-discloser-dispute.model';
import { DialogUploaderInput, DialogFileUploaderComponent } from '../dialog-attachment/dialog-file-attachment.component';
import { DialogSubmitDisputeTransComponent } from '../dialog-submit-dispute-trans/dialog-submit-dispute-trans.component';
import { DisputeTabsService } from '../../_services/dispute-tabs/dispute-tabs.service';

export class DialogInput{
  constructor(public input:disputes.DisputeDiscloserSubmitData[]){
  }
}
@Component({
  selector: 'chexsystem-ws-dialogdisclosure',
  templateUrl: './dialog-disclosure.component.html',
  styleUrls: ['./dialog-disclosure.component.scss']
})
export class DialogdisclosureComponent implements OnInit, AfterViewInit {
  [x: string]: any;

  disputeData: disputes.DisputeDiscloserSubmitData[]=[];
  disputeCount: number;
  isHumantrafficking: boolean = false;
  constructor(public dialogRef: MatDialogRef<DialogdisclosureComponent>,
    private disputeTabsService: DisputeTabsService,
    @Inject(MAT_DIALOG_DATA) public Data:DialogInput,public dialog: MatDialog) {
      // this.disputeData.length = 0;
      
      // Data.input.forEach(element => {
      //   this.disputeData.push(element);
      // });
      // this.disputeCount = this.disputeData.length;

  }

  ngOnInit(): void {
    this.initData();
  }
  ngAfterViewInit() {
    this.Data.input.forEach(element => {
      this.isHumantrafficking = element.isHumanTrafficking; 
   });
  }

  initData(){
    this.disputeData.length = 0;
      
      this.Data.input.forEach(element => {
         this.isHumantrafficking = element.isHumanTrafficking; 
        this.disputeData.push(element);
      });
      this.disputeCount = this.disputeData.length;
  }

  onSubmit(): void{
    const inputData:DialogUploaderInput = new DialogUploaderInput(this.disputeData, "", "DisputeTab");
    const dialogRef = this.dialog.open(
      DialogSubmitDisputeTransComponent,
      {
        panelClass: ["fis-style", "custom-dialog"],
        //width: '700px',
        disableClose: true,
        data: inputData
      }
    );
    dialogRef.afterClosed().subscribe(() => { 

    });
  
    //this.dialogRef.close('OK');
  }

  onCancel(): void{
    this.dialogRef.close('cancel');
  }


    deleteItem(itemD:disputes.DisputeDiscloserSubmitData){
      this.disputeData = this.disputeData.filter(item => item !== itemD);
      this.disputeCount = this.disputeData.length;
      this.disputeTabsService.triggerResetDisputeItemAfterDelete(itemD);
      if(this.disputeData.length === 0){
        this.dialogRef.close('OK');
      }
    }

    closeSummaryDialog() {
      this.dialogRef.close('cancel');
    }
}