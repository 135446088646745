import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChangePassword, IdpUpdateUser, IdpUser, UserDetail, UserLookupResponse } from '../../_models/registration/IdpUser';
import { ApiService } from '../shared/';

@Injectable({
  providedIn: 'root'
})
export class IdpService {
  private readonly Api_Base_URL = environment.idpEndpoint;

  constructor(private _apiService: ApiService) {

  }

  public CreateUser(userRequest:any):Observable<any>{
    const url = this.Api_Base_URL.concat('/users');
    return this._apiService.postWithCustomHeader<any>(url,userRequest,userRequest.captchaToken);
   
  }
  public CheckUserNameCountForReg(reqObj: any):Observable<any>{
    let url = '';
    if(reqObj.isUsername){
    url = this.Api_Base_URL.concat('/userscountforreg?crit=loginName eq '+encodeURIComponent("'"+reqObj.data.split("'").join("''")+"'"));
    } else {
     url = this.Api_Base_URL.concat('/userscountforreg?crit=emailAddress eq '+encodeURIComponent("'"+reqObj.data.split("'").join("''")+"'"));
    }
    return this._apiService.getWithCustomHeader<any>(url,reqObj.token);
  }

  public CheckUserNameCount(inputValue:string):Observable<any>{
    let url = '';
     url = this.Api_Base_URL.concat('/userscount?crit=emailAddress eq '+encodeURIComponent("'"+inputValue.split("'").join("''")+"'"));
      return this._apiService.get<any>(url);
  }
  // public getUserDetail(id:string):Observable<UserDetail>{
  //   const url = this.Api_Base_URL.concat('/users/'+id+'?by=id');
  //   return this._apiService.get<UserDetail>(url)
  //   .pipe(      
  //     catchError(this.handleError)
  //   );
  // }

  // public removeUser(id:string):Observable<UserDetail>{
  //   const url = this.Api_Base_URL.concat('/profiledeletepermanent');
  //   return this._apiService.delete<any>(url, encodeURIComponent(id.split("'").join("''")))
  //   .pipe(      
  //     catchError(this.handleError)
  //   );
  // }

  public UpdateUser(userRequest:IdpUpdateUser):Observable<UserDetail>{
    const url = this.Api_Base_URL.concat('/users');
    return this._apiService.put<UserDetail>(url,userRequest);
  }


  private handleError(error: HttpErrorResponse) {
  
    // if (error.status === 0) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   console.error('An error occurred:', error.error);
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong.
    //   console.error(
    //     `Backend returned code ${error.status}, body was: `, error.error);
    // }
    // Return an observable with a user-facing error message.
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${JSON.stringify(error.error)}`;
    return throwError(() => new Error(errorMessage));
  }

  public getUserProfilePic(loginName:string):Observable<any>{
    const url = this.Api_Base_URL.concat('/profilepic');
    return this._apiService.getFile<any>(url);
  }

  public changePassword(changePasswordRequest:ChangePassword):Observable<any>{
    const url = this.Api_Base_URL.concat('/change-pwd');
    return this._apiService.put<any>(url,changePasswordRequest);
  }
}
